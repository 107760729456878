// @flow
import React from 'react';
import { useFormikContext } from 'formik';

import {
  extractRelevantFieldValuesFromForm,
  isFormEditedBasedOnCurrentValues,
  resetFormValuesToInitialValues,
} from '../common-utils/utils';

import { toastError } from './presentational/notifications/utils';
import { ResetFormButton } from './presentational/buttons/ResetFormButton';
import { Spacer } from './presentational/spacing/Spacer';
import { SaveFormButton } from './presentational/buttons/SaveFormButton';

export const GenericSaveResetButtons = ({
  fieldsConfig,
  onSaveHandler,
  disableSaveAdditionalCriteria = false, // true means save is disabled, false means it does not matter
}) => {
  const { values, initialValues, resetForm, validateForm, isValid } = useFormikContext();

  const formEditedBasedOnCurrentValues = isFormEditedBasedOnCurrentValues(
    values,
    initialValues,
    fieldsConfig
  );
  const shouldSaveBeDisabled =
    !isValid || !formEditedBasedOnCurrentValues || disableSaveAdditionalCriteria;

  const shouldResetBeDisabled = !formEditedBasedOnCurrentValues;

  const handleReset = () => {
    resetFormValuesToInitialValues(values, fieldsConfig, initialValues, resetForm);
  };

  const handleSave = async () => {
    const errors = await validateForm(values);
    if (Object.keys(errors).length > 0) {
      return;
    }
    const payload = extractRelevantFieldValuesFromForm(fieldsConfig, values);
    const { error } = await onSaveHandler(payload);
    if (error) {
      toastError(error);
    }
  };

  return (
    <>
      <ResetFormButton disabled={shouldResetBeDisabled} onClick={handleReset} />
      <Spacer x={1} />
      <SaveFormButton disabled={shouldSaveBeDisabled} onClick={handleSave} />
    </>
  );
};
