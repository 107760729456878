// @flow
import React from 'react';
import { BACKEND_FIELDNAMES } from '@streaming-projects/enums';
import { STREAMING_PROJECTS_SP_DETAILS_CONFIG_MAP } from '@streaming-projects/sp-page/config';
import { SPPhaseAndRiskStatusTooltip } from '@streaming-projects/sp-page/sp-details/SPPhaseAndRiskStatusTooltip';

import { TextField } from '../../../../formik-utils/FormFields';

export const SPSPPhaseContainer = () => {
  const { backendFieldName, displayName, disabled } = STREAMING_PROJECTS_SP_DETAILS_CONFIG_MAP.get(
    BACKEND_FIELDNAMES.SP_PHASE
  );

  return (
    <TextField
      disabled={disabled}
      fieldDisplayName={displayName}
      fieldName={backendFieldName}
      fluid={true}
      tooltip={<SPPhaseAndRiskStatusTooltip />}
    />
  );
};
