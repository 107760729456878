// @flow
export const getLinkForStreamingProjectsHomePage = () => `/streaming-projects`;

export const getLinkForSFDCSPRecordCreateIntermediaryPage = () =>
  `/streaming-projects/sfdc-sp-record-create`;

export const getLinkForSFDCViewsIntermediaryPage = () => `/streaming-projects/sfdc-views`;

export const getLinkForSFDCAccountViewPage = (sfdcAccountId) =>
  `/streaming-projects/sfdc-accounts/${sfdcAccountId}`;

export const getLinkForOrganizationsPage = (orgId) => `/streaming-projects/orgs/${orgId}`;

export const getLinkForStreamingProjectsSPPage = (orgId, spId) =>
  `/orgs/${orgId}/streaming-projects/${spId}`;

export const getLinkForStreamingProjectsFlinkWorkloadsPage = (orgId, spId, flinkWorkloadId) =>
  `/orgs/${orgId}/streaming-projects/${spId}/flink-pools/${flinkWorkloadId}`;

export const getLinkForStreamingProjectsSGWorkloadsPage = (
  orgId,
  spId,
  streamGovernanceWorkloadId
) =>
  `/orgs/${orgId}/streaming-projects/${spId}/stream-governance-details/${streamGovernanceWorkloadId}`;

export const getLinkForStreamingProjectsClusterWorkloadsPage = (orgId, spId, clusterWorkloadId) =>
  `/orgs/${orgId}/streaming-projects/${spId}/cluster-details/${clusterWorkloadId}`;

export const getLinkForStreamingProjectsClusterLinkingWorkloadsPage = (
  orgId,
  spId,
  clusterLinkingWorkloadId
) =>
  `/orgs/${orgId}/streaming-projects/${spId}/cluster-linking-details/${clusterLinkingWorkloadId}`;

export const getLinkForSPDefaultCommitDetailsPage = (orgId) =>
  `/streaming-projects/orgs/${orgId}/default-commit-details`;

export const getLinkForSPUserCommitDetailsPage = (orgId, commitId) =>
  `/streaming-projects/orgs/${orgId}/commit-details/${commitId}`;

export const getLinkForSPForecastStatusPage = (orgId) =>
  `/streaming-projects/orgs/${orgId}/forecast-status`;
