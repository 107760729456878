// @flow
import React from 'react';
import { FormField } from 'semantic-ui-react';

import {
  StyledCheckboxWithAccessibility,
  StyledDatepickerWithAccessibility,
  StyledDatePickerWrapper,
  StyledDropdownWithAccessibility,
  StyledFormikErrorMessage,
  StyledInputWithAccessibility,
  StyledLabel,
} from '../common-utils/styledComponents';
import { NO, YES } from '../constants';

import { Field } from './formikSUIWrapper';
import { BOOLEAN_SELECT_INPUT_TYPE, TEXT_NUMBER_INPUT_TYPE } from './consts';
import { getFormattedOptionsFromOptionsArray } from './utils';

const LabelContainer = ({ fieldDisplayName, tooltip, isMandatory }) => (
  <StyledLabel>
    {fieldDisplayName}
    {isMandatory && <strong style={{ color: 'red', fontSize: '1.2em' }}>*</strong>}
    {tooltip}
  </StyledLabel>
);

export const getFieldNameByFollowingTheGivenPathInFormikValues = (
  pathToFollowInValues,
  colBackendName
) => {
  if (pathToFollowInValues.length > 0) {
    return `${pathToFollowInValues.join('.')}.${colBackendName}`;
  }

  return colBackendName;
};

export const TextField = ({
  fieldName,
  fieldDisplayName,
  icon,
  onChange = () => {},
  tooltip = null,
  addLabel = true,
  addErrorMessage = true,
  errorMessagePosition = null,
  showErrorsOnlyWhenTouched = true,
  pathToFollowInValues = [],
  isMandatory = false,
  ...rest
}) => {
  // For Number Fields,add step="any"

  const stepProp = rest?.type === TEXT_NUMBER_INPUT_TYPE ? { step: 'any' } : {};

  const fieldNameToUse = getFieldNameByFollowingTheGivenPathInFormikValues(
    pathToFollowInValues,
    fieldName
  );

  return (
    <FormField>
      {addLabel && (
        <LabelContainer
          fieldDisplayName={fieldDisplayName}
          isMandatory={isMandatory}
          tooltip={tooltip}
        />
      )}
      <Field
        component={StyledInputWithAccessibility}
        icon={icon}
        name={fieldNameToUse}
        onChange={onChange}
        placeholder={fieldDisplayName}
        {...stepProp}
        {...rest}
      />
      {addErrorMessage && (
        <StyledFormikErrorMessage
          name={fieldNameToUse}
          position={errorMessagePosition}
          showErrorsOnlyWhenTouched={showErrorsOnlyWhenTouched}
        />
      )}
    </FormField>
  );
};

export const FileField = ({
  fieldName,
  fieldDisplayName,
  onChange = () => {},
  tooltip = null,
  addLabel = true,
  addErrorMessage = true,
  icon = null,
  accept = null,
  ...rest
}) => (
  <FormField>
    {addLabel && <LabelContainer fieldDisplayName={fieldDisplayName} tooltip={tooltip} />}

    <Field
      accept={accept}
      clearable={true}
      component={StyledInputWithAccessibility}
      icon={icon}
      name={fieldName}
      onChange={onChange}
      placeholder={fieldDisplayName}
      type="file"
      value={undefined}
      {...rest}
    />
    {addErrorMessage && <StyledFormikErrorMessage name={fieldName} />}
  </FormField>
);

export const DateField = ({
  fieldName,
  fieldDisplayName,
  onChange = () => {},
  tooltip = null,
  addLabel = true,
  addErrorMessage = true,
  isMandatory = false,
  ...rest
}) => (
  <FormField>
    {addLabel && (
      <LabelContainer
        fieldDisplayName={fieldDisplayName}
        isMandatory={isMandatory}
        tooltip={tooltip}
      />
    )}

    <StyledDatePickerWrapper>
      <Field
        clearOnSameDateClick={false}
        clearable={false}
        component={StyledDatepickerWithAccessibility}
        datePickerOnly={true}
        icon={false}
        name={fieldName}
        onChange={onChange}
        placeholder={fieldDisplayName}
        {...rest}
      />
      {addErrorMessage && <StyledFormikErrorMessage name={fieldName} />}
    </StyledDatePickerWrapper>
  </FormField>
);

export const SelectField = ({
  fieldName,
  fieldDisplayName,
  tooltip,
  options,
  addLabel = true,
  addErrorMessage = true,
  addPlaceHolder = true,
  search = false,
  pathToFollowInValues = [],
  showErrorsOnlyWhenTouched = true,
  ...rest
}) => {
  const fieldNameToUse = getFieldNameByFollowingTheGivenPathInFormikValues(
    pathToFollowInValues,
    fieldName
  );

  return (
    <FormField>
      {addLabel && <LabelContainer fieldDisplayName={fieldDisplayName} tooltip={tooltip} />}

      <Field
        clearable={false}
        closeOnBlur={true}
        closeOnEscape={true}
        component={StyledDropdownWithAccessibility}
        name={fieldNameToUse}
        options={getFormattedOptionsFromOptionsArray(options)}
        placeholder={addPlaceHolder ? fieldDisplayName : null}
        search={search}
        selection={true}
        {...rest}
      />
      {addErrorMessage && (
        <StyledFormikErrorMessage
          name={fieldNameToUse}
          showErrorsOnlyWhenTouched={showErrorsOnlyWhenTouched}
        />
      )}
    </FormField>
  );
};

export const BooleanSelectField = ({ fieldName, fieldDisplayName, tooltip, ...rest }) => {
  const yesNoOptions = [
    {
      name: true,
      display_name: YES,
    },
    {
      name: false,
      display_name: NO,
    },
  ];

  return (
    <SelectField
      fieldDisplayName={fieldDisplayName}
      fieldName={fieldName}
      options={yesNoOptions}
      type={BOOLEAN_SELECT_INPUT_TYPE}
      {...rest}
    />
  );
};

export const CheckBoxField = ({
  fieldName,
  fieldDisplayName,
  icon,
  onChange = () => {},
  tooltip = null,
  addLabel = true,
  addErrorMessage = true,
  pathToFollowInValues = [],
  displayCheckBoxBelowLabel = false,
  ...rest
}) => {
  const fieldNameToUse = getFieldNameByFollowingTheGivenPathInFormikValues(
    pathToFollowInValues,
    fieldName
  );

  return (
    <>
      {addLabel && <LabelContainer fieldDisplayName={fieldDisplayName} tooltip={tooltip} />}
      {displayCheckBoxBelowLabel && <br />}
      <Field
        component={StyledCheckboxWithAccessibility}
        icon={icon}
        name={fieldNameToUse}
        onChange={onChange}
        toggle={true}
        type="checkbox"
        {...rest}
      />
      {addErrorMessage && <StyledFormikErrorMessage name={fieldNameToUse} />}
    </>
  );
};

// todo:: Replace all *WithAccessibility with *
