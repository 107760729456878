// @flow
import {
  FORECAST_STATUS_FIELD_NAMES,
  FORECAST_STATUS_GRID_HEADERS,
} from '@streaming-projects/forecast-status-page/enums';
import React, { useContext } from 'react';
import { StyledContainer } from '@src/common-utils/styledComponents';
import { StyledH4 } from '@presentational/headings/StyledH4';
import { DataGrid } from '@components/views/grid-utils/DataGrid';
import { LinkRenderer, TimeStampRenderer } from '@components/views/grid-utils/renderers';
import { SPPushForecastToSFDCButtonRenderer } from '@streaming-projects/forecast-status-page/SPPushForecastToSFDCButtonRenderer';
import { StatusCellRenderer } from '@streaming-projects/forecast-status-page/StatusCellRenderer';
import { SPForecastStatusContext } from '@streaming-projects/forecast-status-page/contexts/SPForecastStatusContext';
import { Spacer } from '@presentational/spacing/Spacer';
import { Tooltip } from '@presentational/Tooltip';
import { If } from '@presentational/If';
import './styles.css';
import { RefreshIcon } from '@presentational/icons/RefreshIcon';

const CELL_WIDTH = 320;
const TABLE_WIDTH = CELL_WIDTH * 5 + 30;

const widthStyles = {
  width: CELL_WIDTH,
  minWidth: CELL_WIDTH,
};

export const SPForecastStatusPage = () => {
  const { processedForecastStatusData, refetch } = useContext(SPForecastStatusContext);

  /**
   * @type {import('ag-grid-community').ColDef[]} columnDefs
   */
  const columnDefs = [
    {
      field: FORECAST_STATUS_GRID_HEADERS.SP_NAME,
      backEndFieldName: FORECAST_STATUS_FIELD_NAMES.SP_NAME,
      headerName: FORECAST_STATUS_GRID_HEADERS.SP_NAME,
      cellRenderer: ({ data }) => (
        <LinkRenderer
          pathName={data[FORECAST_STATUS_FIELD_NAMES.LINK]}
          text={data[FORECAST_STATUS_GRID_HEADERS.SP_NAME]}
        />
      ),
      sort: 'asc',
      ...widthStyles,
    },
    {
      field: FORECAST_STATUS_GRID_HEADERS.TOTAL_SPEND,
      backEndFieldName: FORECAST_STATUS_FIELD_NAMES.TOTAL_SPEND,
      headerName: FORECAST_STATUS_GRID_HEADERS.TOTAL_SPEND,
      hide: true,
    },
    {
      field: FORECAST_STATUS_GRID_HEADERS.TOTAL_SPEND_DISCOUNTED,
      backEndFieldName: FORECAST_STATUS_FIELD_NAMES.TOTAL_SPEND_DISCOUNTED,
      headerName: FORECAST_STATUS_GRID_HEADERS.TOTAL_SPEND_DISCOUNTED,
      hide: true,
    },
    {
      field: FORECAST_STATUS_GRID_HEADERS.VERSION,
      backEndFieldName: FORECAST_STATUS_FIELD_NAMES.VERSION,
      headerName: FORECAST_STATUS_GRID_HEADERS.VERSION,
      hide: true,
    },
    {
      field: FORECAST_STATUS_FIELD_NAMES.CALCULATION_STATUS,
      backEndFieldName: FORECAST_STATUS_FIELD_NAMES.CALCULATION_STATUS,
      headerName: FORECAST_STATUS_GRID_HEADERS.CALCULATION_STATUS,
      cellRenderer: StatusCellRenderer,
      cellClass: ['forecast-cell-center'],
      // todo::SP Research if there is a better option
      headerClass: 'forecast-forecast-header-center',
      ...widthStyles,
      sortable: false,
      filter: false,
    },
    {
      field: FORECAST_STATUS_GRID_HEADERS.FORECAST_DATA,
      backEndFieldName: FORECAST_STATUS_FIELD_NAMES.PUSH_TO_SFDC,
      headerComponent: () => (
        <section className="forecast-center">
          <span>{FORECAST_STATUS_GRID_HEADERS.FORECAST_DATA}</span>
          <Tooltip text="This will send monthly revenue to SFDC" />
        </section>
      ),
      cellRenderer: SPPushForecastToSFDCButtonRenderer,
      cellClass: ['forecast-cell-center'],
      headerClass: 'forecast-forecast-header-center',
      ...widthStyles,
      sortable: false,
      filter: false,
    },
    {
      field: FORECAST_STATUS_GRID_HEADERS.FORECAST_PUBLISHED_AT,
      backEndFieldName: FORECAST_STATUS_FIELD_NAMES.FORECAST_PUBLISHED_AT,
      headerName: FORECAST_STATUS_GRID_HEADERS.FORECAST_PUBLISHED_AT,
      cellRenderer: ({ value }) => <span>{value ? TimeStampRenderer({ value }) : '-'}</span>,
      cellClass: ['forecast-cell-center'],
      headerClass: 'forecast-forecast-header-center',
      ...widthStyles,
      sortable: false,
      filter: false,
    },
    {
      field: FORECAST_STATUS_GRID_HEADERS.LAST_UPDATED_BY,
      backEndFieldName: FORECAST_STATUS_FIELD_NAMES.LAST_UPDATED_BY,
      headerName: FORECAST_STATUS_GRID_HEADERS.LAST_UPDATED_BY,
      cellRenderer: ({ value }) => <span>{value || '-'}</span>,
      cellClass: ['forecast-cell-center'],
      headerClass: 'forecast-forecast-header-center',
      ...widthStyles,
      sortable: false,
      filter: false,
    },
    {
      field: FORECAST_STATUS_FIELD_NAMES.ID,
      backEndFieldName: FORECAST_STATUS_FIELD_NAMES.ID,
      headerName: 'ID',
      hide: true,
    },
    {
      field: FORECAST_STATUS_FIELD_NAMES.LAST_CALC_TIME,
      backEndFieldName: FORECAST_STATUS_FIELD_NAMES.LAST_CALC_TIME,
      hide: true,
    },
    {
      field: FORECAST_STATUS_FIELD_NAMES.SP_ENABLED,
      backEndFieldName: FORECAST_STATUS_FIELD_NAMES.SP_ENABLED,
      hide: true,
    },
    {
      field: FORECAST_STATUS_FIELD_NAMES.LINK,
      backEndFieldName: FORECAST_STATUS_FIELD_NAMES.LINK,
      hide: true,
    },
    {
      field: FORECAST_STATUS_FIELD_NAMES.ORG_ID,
      backEndFieldName: FORECAST_STATUS_FIELD_NAMES.ORG_ID,
      hide: true,
    },
  ];

  return (
    <StyledContainer>
      <Spacer y={10} />
      <If test={processedForecastStatusData?.length}>
        <StyledH4
          text={
            <>
              Please refresh the page to see the latest updates. Alternatively, you can click here
              <Spacer x={8} />
              <RefreshIcon onClick={refetch} />
            </>
          }
        />
      </If>

      <Spacer y={10} />
      <section style={{ overflow: 'auto' }}>
        <DataGrid
          columnDefs={columnDefs}
          height={processedForecastStatusData.length >= 9 ? '600px' : null}
          inputDataSource={processedForecastStatusData}
          noDataMessage="No Enabled Streaming Projects. Please check!"
          rowHeight={60}
          sizeColumnsToFitInitially={true}
          width={TABLE_WIDTH}
        />
      </section>
    </StyledContainer>
  );
};
