// @flow
import React from 'react';
import { useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';
import {
  useDisableStreamingProjectMutation,
  useEnableStreamingProjectMutation,
} from '@streaming-projects/service-definitions/streamingProjectsApi';
import { CheckBoxField } from '@src/formik-utils/FormFields';
import { toastError } from '@presentational/notifications/utils';
import { STREAMING_PROJECTS_SP_ENABLE_DISABLE_CONFIG } from '@streaming-projects/sp-page/config';

export const SPStreamingProjectEnabledContainer = ({
  checked = null,
  addLabel = true,
  spId = null,
  version = null,
}) => {
  const { orgId } = useParams();
  const { isValid } = useFormikContext();
  const [enableStreamingProject] = useEnableStreamingProjectMutation();
  const [disableStreamingProject] = useDisableStreamingProjectMutation();

  const { backendFieldName, displayName } = STREAMING_PROJECTS_SP_ENABLE_DISABLE_CONFIG;

  const checkedProps = checked != null ? { checked } : {};
  return (
    <CheckBoxField
      addLabel={addLabel}
      disabled={!isValid}
      fieldDisplayName={displayName}
      fieldName={backendFieldName}
      onChange={async (event, value, setFieldValue, setFieldTouched, values, checked) => {
        const payload = {
          version: version,
        };

        const funcToCall = checked ? enableStreamingProject : disableStreamingProject;

        const { error } = await funcToCall({
          orgId,
          spId,
          payload,
        });

        if (error) {
          toastError(error);
        }
      }}
      {...checkedProps}
    />
  );
};
