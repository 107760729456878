// @flow
import React, { useCallback, useMemo } from 'react';
import { ShowLoaderIfAnyQueryIsPending } from '@presentational/ShowLoaderIfAnyQueryIsPending';
import { StyledContainer } from '@src/common-utils/styledComponents';
import { StyledH3 } from '@presentational/headings/StyledH3';
import { UserContextProvider } from '@src/contexts/UserContextProvider';
import { SPRateCardMetaContextProvider } from '@streaming-projects/orgs/contexts/SPRateCardMetaContextProvider';
import { useGetSFDCAccountsViewQuery } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { DataFetcherContainer } from '@presentational/DataFetcherContainer';
import { useParams } from 'react-router-dom';
import { StyledH4 } from '@presentational/headings/StyledH4';
import { Spacer } from '@presentational/spacing/Spacer';
import { HierarchicalComponent } from '@presentational/hierarchical/HierarchicalComponent';

import { SFDCOrganizationsGrid } from './SFDCOrganizationsGrid';

export const SFDCAccountsPage = () => {
  const { sfdcAccountId } = useParams();

  const accountInfoText = `Showing all organisations associated with this SFDC account - ${sfdcAccountId}`;

  const hierarchyLevels = [{ name: 'Account', folderIcon: 'folder', color: 'blue' }];

  return (
    <>
      <ShowLoaderIfAnyQueryIsPending />
      <UserContextProvider>
        <SPRateCardMetaContextProvider>
          <StyledContainer>
            <StyledH3 text="My Accounts" />
            <DataFetcherContainer
              dataFetchingArgs={useMemo(
                () => [
                  {
                    sfdcAccountId,
                  },
                  {
                    refetchOnMountOrArgChange: true,
                  },
                ],
                [sfdcAccountId]
              )}
              dataFetchingFunction={useCallback(useGetSFDCAccountsViewQuery, [])}
            >
              {({ accounts }) => {
                return (
                  <>
                    <StyledH4 text={accountInfoText} />
                    <Spacer x={10} y={40} />
                    <HierarchicalComponent
                      LeafComponent={SFDCOrganizationsGrid}
                      data={accounts}
                      expandAllNodesInitially={true}
                      hierarchyLevels={hierarchyLevels}
                      highlightTerm=""
                    />
                  </>
                );
              }}
            </DataFetcherContainer>
          </StyledContainer>
        </SPRateCardMetaContextProvider>
      </UserContextProvider>
    </>
  );
};
