// @flow
import { cloneDeep } from 'lodash';
import { STREAMING_PROJECTS_SP_DETAILS_CONFIG } from '@streaming-projects/sp-page/config';
import {
  BACKEND_FIELDNAMES,
  BASELINE_SP_IDENTIFIER_NAME,
  FRONT_END_DISPLAY_NAMES,
} from '@streaming-projects/enums';

export const getSPDetailsFieldConfigByAddingBaselineNameValidation = () => {
  const fieldsConfig = cloneDeep(STREAMING_PROJECTS_SP_DETAILS_CONFIG);
  fieldsConfig[0].validation = fieldsConfig[0].validation.test(
    'is-not-baseline',
    `${FRONT_END_DISPLAY_NAMES.SP_NAME} cannot be Baseline`,
    (value) => {
      return !value || value.toLowerCase() !== BASELINE_SP_IDENTIFIER_NAME.toLowerCase();
    }
  );
  return fieldsConfig;
};

export const getSPDetailsFieldConfigByAddingSFDCIDValidation = (isSFDCLinked, fieldsConfig) => {
  const fieldsConfigToUse = cloneDeep(fieldsConfig);

  //  IF the Account/Org are not linked, then, no changes are required as SP SFDC ID is not required

  if (!isSFDCLinked) {
    return [...fieldsConfigToUse];
  }

  const sfdcFieldConfig = fieldsConfigToUse.find(
    (x) => x.backendFieldName === BACKEND_FIELDNAMES.SP_SFDC_ID
  );

  sfdcFieldConfig.validation = sfdcFieldConfig.validation.required();

  return [...fieldsConfigToUse];
};

export const getIfSPPhaseAndRiskStatusAreStale = (spSPData) => {
  return spSPData?.sfdc_details?.is_staled_data ?? false;
};
