// @flow
import React, { useEffect, useState } from 'react';

import { StyledH4 } from '../headings/StyledH4';
import { IconWithPopup } from '../IconWithPopup';
import { Spacer } from '../spacing/Spacer';
import { PaginationContainer } from '../pagination/PaginationContainer';

import { Legend } from './Legend';
import { HierarchyLevel } from './HierarchyLevel';

export const generateUniqueKey = (parentKey, levelName, item) => {
  return `${parentKey}-${levelName}-${item.id}-${item.name}`;
};

export const HierarchicalComponent = ({
  data,
  hierarchyLevels,
  LeafComponent,
  highlightTerm,
  expandAllNodesInitially = false,
}) => {
  const [openStates, setOpenStates] = useState({});
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    // If expandAllNodesInitially is not specified, then, a searchTerm is mandatory to open any nodes
    // If none is provided, then, return immediately
    if (!highlightTerm && !expandAllNodesInitially) {
      return;
    }
    const newOpenStates = {};
    const openNodes = (data, level = 0, parentKeys = [], parentKeySoFar = '') => {
      if (level >= hierarchyLevels.length) {
        return;
      }
      data.forEach((item) => {
        const key = generateUniqueKey(parentKeySoFar, hierarchyLevels[level].name, item);
        const shouldOpen =
          expandAllNodesInitially ||
          item.id.toLowerCase().includes(highlightTerm.toLowerCase()) ||
          item.name.toLowerCase().includes(highlightTerm.toLowerCase());

        if (shouldOpen) {
          // If the hierarchy is ABC, then if a match has been found at C, then, only A and B should be open.
          parentKeys.forEach((parentKey) => {
            newOpenStates[parentKey] = true;
          });
        }

        if (level === hierarchyLevels.length - 1) {
          // This is the leaf level, so check its children to see if there is a match
          if (
            expandAllNodesInitially ||
            item.children.some((child) =>
              child.name.toLowerCase().includes(highlightTerm.toLowerCase())
            )
          ) {
            // If a match was found at the last level, then, open till the last level
            // e.g., if the hierarchy is ABC and inside C, we have D and a match has been found at D
            // then, we need to ensure we open each level
            parentKeys.forEach((parentKey) => {
              newOpenStates[parentKey] = true;
            });
            newOpenStates[key] = true;
          }
        }

        openNodes(item.children, level + 1, [...parentKeys, key], key);
      });
    };
    openNodes(data);
    setOpenStates(newOpenStates);
  }, [data, expandAllNodesInitially, hierarchyLevels, highlightTerm]);

  if (data.length === 0) {
    return <StyledH4 text="No results found!" />;
  }

  const toggleOpen = (key) => {
    setOpenStates((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const openAllNodes = () => {
    const newOpenStates = {};
    const openAll = (data, level = 0, parentKeySoFar = '') => {
      if (level >= hierarchyLevels.length) {
        return;
      }
      data.forEach((item) => {
        const key = generateUniqueKey(parentKeySoFar, hierarchyLevels[level].name, item);
        newOpenStates[key] = true;
        openAll(item.children, level + 1, key);
      });
    };
    openAll(data);
    setOpenStates(newOpenStates);
  };

  const closeAllNodes = () => {
    setOpenStates({});
  };

  // Calculate the paginated data
  const startIndex = (activePage - 1) * itemsPerPage;
  const paginatedData = data.slice(startIndex, startIndex + itemsPerPage);

  return (
    <>
      <IconWithPopup
        data-testid="expand-all"
        iconName="plus"
        onClick={() => {
          openAllNodes();
        }}
        popupContent="Expand All"
      />

      <Spacer x={12} />

      <IconWithPopup
        data-testid="collapse-all"
        iconName="minus"
        onClick={() => {
          closeAllNodes();
        }}
        popupContent="Collapse All"
      />

      <Spacer x={120} />

      <Legend hierarchyLevels={hierarchyLevels} />

      <HierarchyLevel
        LeafComponent={LeafComponent}
        data={paginatedData}
        hierarchyLevels={hierarchyLevels}
        highlightTerm={highlightTerm}
        level={0}
        openStates={openStates}
        toggleOpen={toggleOpen}
      />

      <Spacer y={20} />

      <PaginationContainer
        activePage={activePage}
        numberOfRecordsPerPage={itemsPerPage}
        onPageChange={(page) => setActivePage(page)}
        totalNumberOfRecords={data.length}
      />
    </>
  );
};
