// @flow
import React from 'react';
import { SPStreamingProjectEnabledContainer } from '@streaming-projects/orgs/org-top-level/sp-summary/enable-disable/SPStreamingProjectEnabledContainer';
import { SP_SUMMARY_GRID_FIELDNAMES } from '@streaming-projects/orgs/enums';

export const SPStreamingProjectEnabledCellRenderer = (props) => {
  if (props.value == null) {
    return null;
  }

  const spId = props.data[SP_SUMMARY_GRID_FIELDNAMES.ID];
  const version = props.data[SP_SUMMARY_GRID_FIELDNAMES.VERSION];

  return (
    <SPStreamingProjectEnabledContainer
      addLabel={false}
      checked={props.value}
      spId={spId}
      version={version}
    />
  );
};
