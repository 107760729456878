// @flow
import { Grid } from 'semantic-ui-react';
import React, { useContext } from 'react';
import { StyledGridRow } from '@src/common-utils/styledComponents';
import { BACKEND_FIELDNAMES } from '@streaming-projects/enums';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';

import OrgDetailsTextFormInput from './auxilary-components/OrgDetailsTextFormInput';
import { SPRateCardsContainer } from './auxilary-components/SPRateCardsContainer';

export const SPAccountDetailsGrid = ({ disableOnFormErrors }) => {
  const spOrgData = useContext(SPOrgContext);
  const shouldAccountAndOrgFieldsBeDisabled = spOrgData.isSFDCLinked;
  return (
    <Grid columns={2} divided={true}>
      <StyledGridRow>
        <Grid.Column>
          {/* Account ID */}
          <OrgDetailsTextFormInput
            configKey={BACKEND_FIELDNAMES.ACCOUNT_ID}
            disableOnFormErrors={disableOnFormErrors}
            disabled={shouldAccountAndOrgFieldsBeDisabled}
          />
          {/* Account Name */}
          <OrgDetailsTextFormInput
            configKey={BACKEND_FIELDNAMES.ACCOUNT_NAME}
            disableOnFormErrors={disableOnFormErrors}
            disabled={shouldAccountAndOrgFieldsBeDisabled}
          />
        </Grid.Column>

        <Grid.Column>
          {/* ORG Id */}
          <OrgDetailsTextFormInput
            configKey={BACKEND_FIELDNAMES.SFDC_ORG_ID}
            disableOnFormErrors={disableOnFormErrors}
            disabled={shouldAccountAndOrgFieldsBeDisabled}
          />
          {/* ORG Name */}
          <OrgDetailsTextFormInput
            configKey={BACKEND_FIELDNAMES.SFDC_ORG_NAME}
            disableOnFormErrors={disableOnFormErrors}
            disabled={shouldAccountAndOrgFieldsBeDisabled}
          />
        </Grid.Column>
      </StyledGridRow>

      <StyledGridRow>
        <Grid.Column>
          {/* Rate Card Input */}
          <SPRateCardsContainer disableOnFormErrors={disableOnFormErrors} />
        </Grid.Column>
      </StyledGridRow>
    </Grid>
  );
};
