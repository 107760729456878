// @flow
import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { BACKEND_FIELDNAMES } from '@streaming-projects/enums';
import { STREAMING_PROJECTS_BASELINE_DETAILS_CONFIG_MAP } from '@streaming-projects/baseline/config';
import { SPDateChangeWarningModal } from '@streaming-projects/sp-page/sp-details/SPDateChangeWarningModal';
import { DateField } from '@src/formik-utils/FormFields';

export const SPBaselineStartDateContainer = ({
  disableOnFormErrors = null,
  showWarningOnChange = true,
}) => {
  const { values, initialValues } = useFormikContext();
  const [isDateChangeWarningModalOpen, setDateChangeWarningModalOpen] = useState(false);

  const { backendFieldName, displayName } = STREAMING_PROJECTS_BASELINE_DETAILS_CONFIG_MAP.get(
    BACKEND_FIELDNAMES.SP_START_DATE
  );

  return (
    <>
      {showWarningOnChange && (
        <SPDateChangeWarningModal
          isOpen={isDateChangeWarningModalOpen}
          setOpen={setDateChangeWarningModalOpen}
        />
      )}

      <DateField
        disableOnFormErrors={disableOnFormErrors}
        fieldDisplayName={displayName}
        fieldName={backendFieldName}
        fluid={true}
        maxDate={values.end_date}
        onChange={(event, value) => {
          if (showWarningOnChange && value !== initialValues.start_date) {
            setDateChangeWarningModalOpen(true);
          }
        }}
      />
    </>
  );
};
