// @flow

import React, { useContext, useState } from 'react';
import {
  CLUSTER_LINKING_RESOURCES_GRID_FIELDNAMES,
  CLUSTER_LINKING_RESOURCES_GRID_HEADERS,
} from '@streaming-projects/orgs/enums';
import { StyledContainer } from '@src/common-utils/styledComponents';
import { ConfirmModal } from '@presentational/modals/ConfirmModal';
import { DataGrid } from '@components/views/grid-utils/DataGrid';
import {
  useAddClusterLinkingWorkloadMutation,
  useGetAvailableClusterLinkingResourcesQuery,
} from '@streaming-projects/service-definitions/streamingProjectsApi';
import { handleGridRowSelectionChanged } from '@src/common-utils/utils';
import { useHistory, useParams } from 'react-router-dom';
import { SPStreamingProjectContext } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContext';
import { toastError } from '@presentational/notifications/utils';
import { getLinkForStreamingProjectsClusterLinkingWorkloadsPage } from '@streaming-projects/links';
import { ADD_NEW_WORKLOAD_MESSAGE, NO_FREE_RESOURCE_MESSAGE } from '@streaming-projects/constants';
import { ResourceSelectionRadioButtonRenderer } from '@components/views/grid-utils/renderers';
import { QueryError } from '@presentational/QueryError';

export const SPClusterLinkingWorkloadAddModal = ({ isOpen, setOpen }) => {
  const { orgId, spId } = useParams();
  const [addWorkload] = useAddClusterLinkingWorkloadMutation();
  const { push } = useHistory();

  const spStreamingProjectData = useContext(SPStreamingProjectContext);

  const [selectedRow, setSelectedRow] = useState(null);

  const columnDefs = [
    {
      field: 'Selection',
      sortable: false,
      filter: false,
      cellRenderer: (props) =>
        ResourceSelectionRadioButtonRenderer(
          props,
          selectedRow,
          CLUSTER_LINKING_RESOURCES_GRID_HEADERS.ID
        ),
    },
    {
      field: CLUSTER_LINKING_RESOURCES_GRID_HEADERS.ID,
      backEndFieldName: CLUSTER_LINKING_RESOURCES_GRID_FIELDNAMES.ID,
      hide: true,
    },
    {
      field: CLUSTER_LINKING_RESOURCES_GRID_HEADERS.NAME,
      backEndFieldName: CLUSTER_LINKING_RESOURCES_GRID_FIELDNAMES.NAME,
    },
    {
      field: CLUSTER_LINKING_RESOURCES_GRID_HEADERS.SOURCE_CLUSTER_NAME,
      backEndFieldName: CLUSTER_LINKING_RESOURCES_GRID_FIELDNAMES.SOURCE_CLUSTER_NAME,
    },
    {
      field: CLUSTER_LINKING_RESOURCES_GRID_HEADERS.DESTINATION_CLUSTER_NAME,
      backEndFieldName: CLUSTER_LINKING_RESOURCES_GRID_FIELDNAMES.DESTINATION_CLUSTER_NAME,
    },
  ];

  const onSelectionChanged = handleGridRowSelectionChanged(
    (rowData) => setSelectedRow(rowData),
    () => setSelectedRow(null)
  );

  // Note: We cannot use DataFetcherComponent here as we need more control
  const { currentData, error, isLoading, isFetching } = useGetAvailableClusterLinkingResourcesQuery(
    { orgId, spId },
    {
      refetchOnMountOrArgChange: true,
      skip: !orgId || !isOpen,
    }
  );

  if (isLoading || isFetching || !isOpen) {
    return null;
  }

  if (error) {
    return <QueryError error={error} />;
  }

  const resourcesToUse = currentData?.resources?.cluster_linking_resources ?? [];

  return (
    <StyledContainer>
      <ConfirmModal
        body={
          <StyledContainer>
            <DataGrid
              columnDefs={columnDefs}
              inputDataSource={resourcesToUse}
              noDataMessage={NO_FREE_RESOURCE_MESSAGE}
              onSelectionChanged={onSelectionChanged}
              rowSelection="single"
              sizeColumnsToFitInitially={true}
              tooltipShowDelay={0}
            />
          </StyledContainer>
        }
        cancelButtonNegative={true}
        disabled={!selectedRow}
        fullScreen={true}
        header={ADD_NEW_WORKLOAD_MESSAGE}
        isOpen={isOpen}
        okButtonNegative={false}
        okButtonText="Continue"
        onClickHandlerForCancel={() => {
          setSelectedRow(null);
          setOpen(false);
        }}
        onClickHandlerForOK={async () => {
          const { error, data } = await addWorkload({
            orgId,
            spId,
            payload: {
              name: 'Default',
              cluster_linking_resource_id: selectedRow[CLUSTER_LINKING_RESOURCES_GRID_HEADERS.ID],
              sp_version: spStreamingProjectData?.sp?.version,
            },
          });
          if (error) {
            toastError(error);
          } else {
            push(getLinkForStreamingProjectsClusterLinkingWorkloadsPage(orgId, spId, data.id));
          }
          setOpen(false);
        }}
      />
    </StyledContainer>
  );
};
