// @flow
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { StyledSFDCCreateContainer } from '@src/common-utils/styledComponents';
import { StyledH4 } from '@presentational/headings/StyledH4';
import { useLazyGetStreamingProjectFromSFDCViewQuery } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { QueryError30 } from '@presentational/QueryError30';

export const SFDCSPViewsIntermediaryPage = () => {
  const [error, setError] = useState(null);
  // Note, we are using the "Lazy" version here so that we can call it inside the createSPE function
  const [openFromSFDCAPI] = useLazyGetStreamingProjectFromSFDCViewQuery();
  const { push } = useHistory();

  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const sfdcAccountId = urlSearchParams.get('sfdc_account_id');
  const sfdcOrgId = urlSearchParams.get('sfdc_cloud_org_id');
  const sfdcSPRecordId = urlSearchParams.get('sfdc_sp_record_id');
  const sfdcOpportunityId = urlSearchParams.get('sfdc_opportunity_id');
  const sfdcQuoteId = urlSearchParams.get('sfdc_quote_id');

  useEffect(() => {
    const createSPE = async () => {
      const { error, data } = await openFromSFDCAPI({
        sfdcAccountId,
        sfdcOrgId,
        sfdcSPRecordId,
        sfdcOpportunityId,
        sfdcQuoteId,
      });

      if (!error) {
        push(`${data.redirection_url}`);
        return;
      }

      setError(error);
    };
    // eslint-disable-next-line no-console
    createSPE().then(() => console.log('SPE Created!'));
  }, [
    openFromSFDCAPI,
    push,
    sfdcAccountId,
    sfdcOpportunityId,
    sfdcOrgId,
    sfdcQuoteId,
    sfdcSPRecordId,
  ]);

  if (error) {
    return <QueryError30 error={error} />;
  }

  return (
    <StyledSFDCCreateContainer>
      <StyledH4 text={<>SP redirection is in progress....</>} />
    </StyledSFDCCreateContainer>
  );
};
