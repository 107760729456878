// @flow
import React, { useContext } from 'react';
import { useForecastPushToSFDCMutation } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { toastError, toastSuccess } from '@presentational/notifications/utils';
import { useParams } from 'react-router-dom';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';
import { SP_PRICING_STATUS_STATES } from '@src/constants';
import { ButtonWithPopup } from '@presentational/ButtonWithPopup';
import {
  FORECAST_STATUS_FIELD_NAMES,
  FORECAST_STATUS_GRID_HEADERS,
} from '@streaming-projects/forecast-status-page/enums';

import { getForecastMRRBtnPopupContent } from './utils';

const getIfLastPushedDataIsStillValid = (data) => {
  const lastCalcTime = data[FORECAST_STATUS_FIELD_NAMES.LAST_CALC_TIME];
  const lastPushToSFDC = data[FORECAST_STATUS_GRID_HEADERS.FORECAST_PUBLISHED_AT];

  if (!lastCalcTime || !lastPushToSFDC) {
    return false;
  }

  const lastCalcTimeAsDate = new Date(lastCalcTime);
  const lastPushToSFDCAsDate = new Date(lastPushToSFDC);

  return lastPushToSFDCAsDate > lastCalcTimeAsDate;
};

export const SPPushForecastToSFDCButtonRenderer = ({ data }) => {
  const { orgId } = useParams();
  const [forecastPushToSFDCMutation] = useForecastPushToSFDCMutation();
  const { isSFDCLinked } = useContext(SPOrgContext);
  const isLastPushedDataStillValid = getIfLastPushedDataIsStillValid(data);
  const shouldPushToSFDCButtonBeEnabled =
    isSFDCLinked &&
    data.calculation_status === SP_PRICING_STATUS_STATES.SUCCESS &&
    !isLastPushedDataStillValid;
  const handlePushToSFDC = async () => {
    const { error } = await forecastPushToSFDCMutation({
      orgId,
      spId: data.id,
      body: {
        version: data.Version,
      },
    });

    if (error) {
      toastError(error);
    } else {
      toastSuccess('Successfully pushed MRR to SFDC!');
    }
  };
  const popupContent = getForecastMRRBtnPopupContent(
    isSFDCLinked,
    isLastPushedDataStillValid,
    data
  );
  return (
    <ButtonWithPopup
      circular={true}
      disabled={!shouldPushToSFDCButtonBeEnabled}
      onClick={handlePushToSFDC}
      popupContent={popupContent}
      size="mini"
      text={FORECAST_STATUS_GRID_HEADERS.FORECAST_DATA}
    />
  );
};
