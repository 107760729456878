// @flow

import React, { useContext } from 'react';
import { getAccordianPanelDetailsObject } from '@src/common-utils/utils';
import { AccordionsList } from '@presentational/accordions/AccordionsList';
import { SPStreamingProjectGenericDetailsContainer } from '@streaming-projects/sp-page/sp-details/SPStreamingProjectGenericDetailsContainer';
import { SPFlinkSPPageWorkloadSummaryContainer } from '@streaming-projects/sp-page/workloads-summary/flink-workloads-summary/SPFlinkSPPageWorkloadSummaryContainer';
import { SPStreamGovernanceSPPageWorkloadSummaryContainer } from '@streaming-projects/sp-page/workloads-summary/stream-governance-workloads-summary/SPStreamGovernanceSPPageWorkloadSummaryContainer';
import { SPClusterLinkingSPPageWorkloadSummaryContainer } from '@streaming-projects/sp-page/workloads-summary/cluster-linking-workloads-summary/SPClusterLinkingSPPageWorkloadSummaryContainer';

import { SPClustersSPPageWorkloadSummaryContainer } from '../workloads-summary/cluster-workloads-summary/SPClustersSPPageWorkloadSummaryContainer';
import { SPStreamingProjectContext } from '../contexts/SPStreamingProjectContext';

import { SPStreamingProjectPricingSummaryContainer } from './SPStreamingProjectPricingSummaryContainer';

export const SPStreamingProjectAccordionsContainer = () => {
  const spSPData = useContext(SPStreamingProjectContext);
  const isBaselineSP = spSPData?.isBaseLineSP ?? false;

  const accordionPanels = [
    getAccordianPanelDetailsObject(
      'Streaming Project Details',
      <SPStreamingProjectGenericDetailsContainer />
    ),
  ];

  accordionPanels.push(
    getAccordianPanelDetailsObject(
      'Kafka/Connect/ksqlDB Workloads',
      <SPClustersSPPageWorkloadSummaryContainer />
    )
  );

  accordionPanels.push(
    getAccordianPanelDetailsObject(
      'Cluster Linking Workloads',
      <SPClusterLinkingSPPageWorkloadSummaryContainer />
    )
  );

  accordionPanels.push(
    getAccordianPanelDetailsObject('Flink Workloads', <SPFlinkSPPageWorkloadSummaryContainer />)
  );

  accordionPanels.push(
    getAccordianPanelDetailsObject(
      'Stream Governance Workloads',
      <SPStreamGovernanceSPPageWorkloadSummaryContainer />
    )
  );

  accordionPanels.push(
    getAccordianPanelDetailsObject(
      isBaselineSP ? 'Baseline Pricing Summary' : 'Streaming Project Pricing Summary',
      <SPStreamingProjectPricingSummaryContainer />
    )
  );

  return (
    <AccordionsList
      initialIndexes={[0]}
      panels={accordionPanels}
      showOnlyTitleInCollapsedState={true}
    />
  );
};
