// @flow
import { CopyButton } from '@presentational/buttons/CopyButton';
import React from 'react';
import { useCopySPOrgMutation } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { useParams } from 'react-router-dom';
import { toastError, toastSuccess } from '@presentational/notifications/utils';
import { Spacer } from '@presentational/spacing/Spacer';
import { getLinkForOrganizationsPage } from '@streaming-projects/links';

export const SPORGCopyContainer = () => {
  const [copySPOrg] = useCopySPOrgMutation();
  const { orgId } = useParams();

  return (
    <CopyButton
      onClick={async () => {
        const { error, data } = await copySPOrg({
          orgId,
        });
        if (error) {
          toastError(error);
        } else {
          toastSuccess(
            <>
              Org copied successfully. You can open it by clicking <Spacer x={5} />
              <a href={getLinkForOrganizationsPage(data?.org_id)}>
                <strong>here!!!</strong>
              </a>
            </>,
            false
          );
        }
      }}
    />
  );
};
