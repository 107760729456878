// @flow
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import { SPAccountDetailsGrid } from '@streaming-projects/orgs/SPAccountDetailsGrid';
import { Spacer } from '@presentational/spacing/Spacer';
import { STREAMING_PROJECTS_ORG_DETAILS_CONFIG } from '@streaming-projects/orgs/config';
import { useUpdateSPOrgMutation } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { GenericSaveResetButtons } from '@components/GenericSaveResetButtons';
import { toastError, toastSuccess } from '@presentational/notifications/utils';

export const SPOrganizationDetailsContainer = () => {
  const { orgId } = useParams();
  const [updateSPOrgDetails] = useUpdateSPOrgMutation();
  const spOrgData = useContext(SPOrgContext);
  const entityVersion = spOrgData?.org?.version;
  return (
    <>
      <PaddedAndRaisedSegment>
        <SPAccountDetailsGrid disableOnFormErrors={null} />
        <Spacer y={40} />

        <GenericSaveResetButtons
          fieldsConfig={STREAMING_PROJECTS_ORG_DETAILS_CONFIG}
          onSaveHandler={async (payload) => {
            const { error, data } = await updateSPOrgDetails({
              orgId,
              payload: {
                ...payload,
                org_id: orgId,
                entity_version: entityVersion,
              },
            });

            if (error) {
              toastError(error);
            } else {
              if (data.is_sfdc_linked) {
                toastSuccess(
                  <>
                    <p>Org successfully updated and linked to SFDC.</p>
                    <p>
                      Account ID/Name and Org ID/Name are not editable for SFDC-linked orgs on
                      Estimator.
                    </p>
                  </>
                );
              }
            }
          }}
        />
      </PaddedAndRaisedSegment>
    </>
  );
};
