// @flow
import React from 'react';
import { Popup } from 'semantic-ui-react';

import { PrimaryButton } from './buttons/PrimaryButton';

export const ButtonWithPopup = ({
  popupContent,
  circular = true,
  onClick = () => {},
  text = '',
  disabled = false,
  style = {},
  size = null,
  color = null,
}) => {
  return (
    <Popup
      content={popupContent}
      disabled={!popupContent}
      flowing={true}
      hoverable={true}
      on={['hover']}
      trigger={
        <span>
          <PrimaryButton
            circular={circular}
            color={color}
            disabled={disabled}
            onClick={onClick}
            size={size}
            style={style}
            text={text}
          />
        </span>
      }
    />
  );
};
