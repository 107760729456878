// @flow
import React from 'react';
// eslint-disable-next-line import/no-unresolved
import { getLinkForStreamingProjectsHomePage } from '@streaming-projects/links';

import { StyledMenuItem } from '../../common-utils/styledComponents';
import { StreamingProjectsIcon } from '../presentational/icons/StreamingProjectsIcon';
import { Spacer } from '../presentational/spacing/Spacer';
import { ALinkWithInheritedColorAndUnderline } from '../presentational/ALinkWithInheritedColorAndUnderline';

export const StreamingProjectsViewLink = () => {
  return (
    <StyledMenuItem>
      <ALinkWithInheritedColorAndUnderline href={getLinkForStreamingProjectsHomePage()}>
        <StreamingProjectsIcon />
        <Spacer x={5} />
        Go to Integrated Cloud Estimator
      </ALinkWithInheritedColorAndUnderline>
    </StyledMenuItem>
  );
};
