// @flow
export const FORECAST_STATUS_GRID_HEADERS = {
  SP_NAME: 'SP Name',
  TOTAL_SPEND: 'Total Spend',
  TOTAL_SPEND_DISCOUNTED: 'Total Spend Discounted',
  FORECAST_DATA: 'Forecast Data',
  CALCULATION_STATUS: 'Total Spend Discounted',
  FORECAST_PUBLISHED_AT: 'Last Push to SFDC',
  LAST_UPDATED_BY: 'Last Updated By',
  LAST_CALC_TIME: 'Last Calc Time',
  VERSION: 'Version',
};

export const FORECAST_STATUS_FIELD_NAMES = {
  ID: 'id',
  ORG_ID: 'org_id',
  SP_NAME: 'name',
  TOTAL_SPEND: 'total_spend',
  TOTAL_SPEND_DISCOUNTED: 'total_spend_discounted',
  PUSH_TO_SFDC: 'push_to_sfdc',
  CALCULATION_STATUS: 'calculation_status',
  SP_ENABLED: 'enabled',
  LINK: 'link',
  FORECAST_PUBLISHED_AT: 'forecast_published_at',
  LAST_UPDATED_BY: 'updated_by',
  LAST_CALC_TIME: 'last_calc_time',
  VERSION: 'version',
};
