// @flow
import React, { useContext } from 'react';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';
import { SecondaryTopBar } from '@components/breadcrumbs/SecondaryTopBar';
import { getSPForecastStatusPageBreadcrumbs } from '@streaming-projects/utils';

export const SPForecastStatusPageBreadcrumbs = () => {
  const spOrgData = useContext(SPOrgContext);
  return (
    <SecondaryTopBar
      sectionsToShow={getSPForecastStatusPageBreadcrumbs(spOrgData)}
      showLastUpdatedMessage={false}
    />
  );
};
