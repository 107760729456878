// @flow
import React from 'react';
import { SPForecastStatusPageBreadcrumbs } from '@streaming-projects/forecast-status-page/SPForecastStatusPageBreadcrumbs';
import { SPForecastStatusContextProvider } from '@streaming-projects/forecast-status-page/contexts/SPForecastStatusContextProvider';
import { SPForecastStatusPage } from '@streaming-projects/forecast-status-page/SPForecastStatusPage';
import { UserContextProvider } from '@src/contexts/UserContextProvider';
import { ShowLoaderIfAnyQueryIsPending } from '@presentational/ShowLoaderIfAnyQueryIsPending';
import { SPOrgContextProvider } from '@streaming-projects/orgs/contexts/SPOrgContextProvider';

export const SPForecastStatusPageTopLevelContainer = () => {
  return (
    <>
      <ShowLoaderIfAnyQueryIsPending />
      <UserContextProvider>
        <SPOrgContextProvider>
          <SPForecastStatusContextProvider>
            <SPForecastStatusPageBreadcrumbs />
            <SPForecastStatusPage />
          </SPForecastStatusContextProvider>
        </SPOrgContextProvider>
      </UserContextProvider>
    </>
  );
};
