// @flow
import React from 'react';
import { string } from 'yup';
import {
  RATE_CARD_DISPLAY_NAME,
  SFDC_ACCOUNT_NAME_DISPLAY_NAME,
  SFDC_ORGANISATION_NAME_DISPLAY_NAME,
  SP_SFDC_ACCOUNT_ID_DISPLAY_NAME,
  SP_SFDC_ORGANIZATION_ID_DISPLAY_NAME,
} from '@src/constants';
import { SELECT_INPUT_TYPE, TEXT_INPUT_TYPE } from '@src/formik-utils/consts';
import { convertConfigArrayToMap } from '@src/configuration/utils';
import { BACKEND_FIELDNAMES } from '@streaming-projects/enums';
import {
  getMappedDataValueGetter,
  getRegionName,
} from '@streaming-projects/resource-definitions/auxilary-components/MappedDataRenderer';
import { PercentageRenderer } from '@components/views/grid-utils/renderers';
import { EXTERNAL_CLUSTER_DEFINITION } from '@streaming-projects/resource-definitions/cluster-linking-resource-definitions/utils';
import { ENABLED_DISABLED_FILTER_PARAMS } from '@components/views/grid-utils/constants';
import { SPStreamingProjectEnabledCellRenderer } from '@streaming-projects/orgs/org-top-level/sp-summary/enable-disable/SPStreamingProjectEnabledCellRenderer';
import { SPStreamingProjectCopyRenderer } from '@streaming-projects/orgs/org-top-level/sp-summary/copy/SPStreamingProjectCopyRenderer';

import {
  CLUSTER_RESOURCES_GRID_FIELDNAMES,
  CLUSTER_RESOURCES_GRID_HEADERS,
  SP_SUMMARY_GRID_FIELDNAMES,
  SP_SUMMARY_GRID_HEADERS,
} from './enums';
import { SPNameRenderer } from './org-top-level/sp-summary/SPNameRenderer';
import { SPDeleteRenderer } from './org-top-level/sp-summary/SPDeleteRenderer';

export const STREAMING_PROJECTS_ORG_DETAILS_CONFIG = [
  {
    displayName: SP_SFDC_ACCOUNT_ID_DISPLAY_NAME,
    backendFieldName: `${BACKEND_FIELDNAMES.ACCOUNT_ID}`,
    validation: string().label(SP_SFDC_ACCOUNT_ID_DISPLAY_NAME).required(),
    icon: { name: 'folder', color: 'blue' },
    inputType: TEXT_INPUT_TYPE,
  },
  {
    displayName: SFDC_ACCOUNT_NAME_DISPLAY_NAME,
    backendFieldName: `${BACKEND_FIELDNAMES.ACCOUNT_NAME}`,
    validation: string().label(SFDC_ACCOUNT_NAME_DISPLAY_NAME).required(),
    icon: { name: 'folder', color: 'blue' },
    inputType: TEXT_INPUT_TYPE,
  },
  {
    displayName: SP_SFDC_ORGANIZATION_ID_DISPLAY_NAME,
    backendFieldName: `${BACKEND_FIELDNAMES.SFDC_ORG_ID}`,
    validation: string().label(SP_SFDC_ORGANIZATION_ID_DISPLAY_NAME).required(),
    icon: { name: 'folder', color: 'teal' },
    inputType: TEXT_INPUT_TYPE,
  },
  {
    displayName: SFDC_ORGANISATION_NAME_DISPLAY_NAME,
    backendFieldName: `${BACKEND_FIELDNAMES.SFDC_ORG_NAME}`,
    validation: string().label(SFDC_ORGANISATION_NAME_DISPLAY_NAME).required(),
    icon: { name: 'folder', color: 'teal' },
    inputType: TEXT_INPUT_TYPE,
  },
  {
    displayName: RATE_CARD_DISPLAY_NAME,
    backendFieldName: `${BACKEND_FIELDNAMES.RATE_CARD}`,
    validation: string().label(RATE_CARD_DISPLAY_NAME).required(),
    inputType: SELECT_INPUT_TYPE,
  },
];

export const STREAMING_PROJECTS_ORG_DETAILS_CONFIG_MAP = convertConfigArrayToMap(
  STREAMING_PROJECTS_ORG_DETAILS_CONFIG
);

export const SP_ORG_TOP_LEVEL_CONFIG = [...STREAMING_PROJECTS_ORG_DETAILS_CONFIG];

// todo::SP Get this from where? Probably a separate call? Discuss with BE
export const SP_COMMIT_CONFIGS = {
  deal_types: ['New Commit', 'Renewal'],
  marketplaces: ['None', 'AWS', 'GCP', 'Azure'],
  payment_schedules: ['Prepaid', 'Arrears'],
  support_tiers: ['Free', 'Developer', 'Business', 'Premier'],
  rate_cards: [
    {
      id: 1,
      name: '8/1',
      label: 'Aug 1, 2020 (8/1) (Admins Only)',
      actionable: true,
    },
    {
      id: 2,
      name: '10/1',
      label: 'Oct 1, 2020 (10/1)',
      actionable: true,
    },
    {
      id: 3,
      name: '3/5/2024',
      label: 'Mar 5, 2024 (3/5/2024)',
      actionable: true,
    },
    {
      id: 4,
      name: '4/11/2024',
      label: 'Apr 11, 2024 (4/11/2024)',
      actionable: true,
    },
  ],
};

export const STREAMING_PROJECT_SUMMARY_GRID_CONFIG = [
  {
    field: SP_SUMMARY_GRID_FIELDNAMES.NAME,
    headerName: SP_SUMMARY_GRID_HEADERS.NAME,
    cellRenderer: SPNameRenderer,
    isApplicableForBaseline: true,
    isApplicableForNonBaseline: true,
  },
  {
    field: SP_SUMMARY_GRID_FIELDNAMES.ID,
    headerName: SP_SUMMARY_GRID_HEADERS.ID,
    hide: true,
    isApplicableForBaseline: true,
    isApplicableForNonBaseline: true,
  },
  {
    field: SP_SUMMARY_GRID_HEADERS.IS_ENABLED,
    filter: true,
    filterParams: ENABLED_DISABLED_FILTER_PARAMS,
    cellRenderer: SPStreamingProjectEnabledCellRenderer,
    backEndFieldName: SP_SUMMARY_GRID_FIELDNAMES.IS_ENABLED,
    isApplicableForBaseline: false,
    isApplicableForNonBaseline: true,
  },
  // todo::SP add SFDC ID when available
  // {
  //   field: SP_SUMMARY_GRID_FIELDNAMES.SFDC_ID,
  //   headerName: SP_SUMMARY_GRID_HEADERS.SFDC_ID,
  // },
  {
    field: SP_SUMMARY_GRID_FIELDNAMES.SP_PROVISION_DATE,
    headerName: SP_SUMMARY_GRID_HEADERS.SP_PROVISION_DATE,
    isApplicableForBaseline: false,
    isApplicableForNonBaseline: true,
  },
  {
    field: SP_SUMMARY_GRID_FIELDNAMES.SP_GO_LIVE_DATE,
    headerName: SP_SUMMARY_GRID_HEADERS.SP_GO_LIVE_DATE,
    isApplicableForBaseline: false,
    isApplicableForNonBaseline: true,
  },
  {
    field: SP_SUMMARY_GRID_FIELDNAMES.SP_RAMPED_DATE,
    headerName: SP_SUMMARY_GRID_HEADERS.SP_RAMPED_DATE,
    isApplicableForBaseline: false,
    isApplicableForNonBaseline: true,
  },
  {
    field: SP_SUMMARY_GRID_FIELDNAMES.START_DATE,
    headerName: SP_SUMMARY_GRID_HEADERS.START_DATE,
    isApplicableForBaseline: true,
    isApplicableForNonBaseline: false,
  },
  {
    field: SP_SUMMARY_GRID_FIELDNAMES.END_DATE,
    headerName: SP_SUMMARY_GRID_HEADERS.END_DATE,
    isApplicableForBaseline: true,
    isApplicableForNonBaseline: true,
  },
  {
    field: SP_SUMMARY_GRID_FIELDNAMES.VERSION,
    headerName: SP_SUMMARY_GRID_HEADERS.VERSION,
    hide: true,
    isApplicableForBaseline: true,
    isApplicableForNonBaseline: true,
  },
  {
    field: '',
    filter: false,
    cellRenderer: SPStreamingProjectCopyRenderer,
    suppressSizeToFit: true,
    maxWidth: 60,
    sortable: false,
    isApplicableForBaseline: false,
    isApplicableForNonBaseline: true,
  },
  {
    field: '',
    suppressSizeToFit: true,
    maxWidth: 50,
    cellRenderer: SPDeleteRenderer,
    filter: false,
    sortable: false,
    isApplicableForBaseline: true,
    isApplicableForNonBaseline: true,
  },
];

export const getSPClusterResourceConfig = ({
  resourceConfigurationsNameLabelsMap,
  providerNameLabelMap,
  providerRegionsMap,
}) => {
  return [
    {
      field: CLUSTER_RESOURCES_GRID_HEADERS.TYPE,
      backEndFieldName: CLUSTER_RESOURCES_GRID_FIELDNAMES.CLUSTER_TYPE,
      valueGetter: getMappedDataValueGetter(
        resourceConfigurationsNameLabelsMap[CLUSTER_RESOURCES_GRID_FIELDNAMES.CLUSTER_TYPE]
      ),
      headerTooltip: CLUSTER_RESOURCES_GRID_HEADERS.TYPE,
    },
    {
      field: CLUSTER_RESOURCES_GRID_HEADERS.NETWORKING_TYPE,
      backEndFieldName: CLUSTER_RESOURCES_GRID_FIELDNAMES.NETWORKING_TYPE,
      valueGetter: getMappedDataValueGetter(
        resourceConfigurationsNameLabelsMap[CLUSTER_RESOURCES_GRID_FIELDNAMES.NETWORKING_TYPE]
      ),
      headerTooltip: CLUSTER_RESOURCES_GRID_HEADERS.NETWORKING_TYPE,
    },
    {
      field: CLUSTER_RESOURCES_GRID_HEADERS.PROVIDER,
      backEndFieldName: CLUSTER_RESOURCES_GRID_FIELDNAMES.PROVIDER,
      valueGetter: getMappedDataValueGetter(providerNameLabelMap),
      headerTooltip: CLUSTER_RESOURCES_GRID_HEADERS.PROVIDER,
    },
    {
      field: CLUSTER_RESOURCES_GRID_HEADERS.REGION,
      backEndFieldName: CLUSTER_RESOURCES_GRID_FIELDNAMES.REGION,
      tooltipValueGetter: (params) => getRegionName(params, providerRegionsMap),
      // For external cluster, Provider is "-". providerRegionsMap[data.Provider]->undefined, hence used optional chaining
      valueGetter: (params) => getRegionName(params, providerRegionsMap),
      headerTooltip: CLUSTER_RESOURCES_GRID_HEADERS.REGION,
    },
    {
      field: CLUSTER_RESOURCES_GRID_HEADERS.AVAILABILITY,
      backEndFieldName: CLUSTER_RESOURCES_GRID_FIELDNAMES.AVAILABILITY,
      valueGetter: getMappedDataValueGetter(
        resourceConfigurationsNameLabelsMap[CLUSTER_RESOURCES_GRID_FIELDNAMES.AVAILABILITY]
      ),
      headerTooltip: CLUSTER_RESOURCES_GRID_HEADERS.AVAILABILITY,
    },
    {
      field: CLUSTER_RESOURCES_GRID_HEADERS.SLA,
      backEndFieldName: CLUSTER_RESOURCES_GRID_FIELDNAMES.SLA,
      cellRenderer: ({ data, value }) => {
        // we don't want to show % for external cluster value which is "-"
        if (data[CLUSTER_RESOURCES_GRID_HEADERS.ID] === EXTERNAL_CLUSTER_DEFINITION.id) {
          return <>{EXTERNAL_CLUSTER_DEFINITION.sla}</>;
        }
        return <PercentageRenderer value={value} />;
      },
      headerTooltip: CLUSTER_RESOURCES_GRID_HEADERS.SLA,
    },
  ];
};
