// @flow

import React from 'react';

import { StyledButton } from '../../../common-utils/styledComponents';

export const PrimaryButton = ({
  size,
  text,
  onClick,
  disabled = false,
  circular = false,
  compact = false,
  floated = null,
  style = {},
  color = null,
}) => (
  <StyledButton
    circular={circular}
    color={color}
    compact={compact}
    data-testid="primary-button"
    disabled={disabled}
    floated={floated}
    onClick={onClick}
    primary={!color}
    size={size}
    style={style}
    type="button"
  >
    {text}
  </StyledButton>
);
