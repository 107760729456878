// @flow
import { ConfirmModal } from '@presentational/modals/ConfirmModal';
import { toastError, toastSuccess } from '@presentational/notifications/utils';
import { Spacer } from '@presentational/spacing/Spacer';
import { StyledContainer } from '@src/common-utils/styledComponents';
import { getFormikFormInputsFromColConfigAndInputSource } from '@src/common-utils/utils';
import { Form } from '@src/formik-utils/formikSUIWrapper';
import { getLinkForOrganizationsPage } from '@streaming-projects/links';
import { SPAccountDetailsGrid } from '@streaming-projects/orgs/SPAccountDetailsGrid';
import { STREAMING_PROJECTS_ORG_DETAILS_CONFIG } from '@streaming-projects/orgs/config';
import { useAddOrganizationMutation } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { Formik } from 'formik';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const SPOrgAddModal = ({ defaultValues = {}, isOpen, onClose }) => {
  const [addOrg] = useAddOrganizationMutation();
  const { push } = useHistory();

  const { initialValues, validationSchema } = getFormikFormInputsFromColConfigAndInputSource(
    STREAMING_PROJECTS_ORG_DETAILS_CONFIG,
    defaultValues,
    null
  );

  return (
    <StyledContainer data-testid="sp-org-add-modal">
      <Formik
        autoComplete="off"
        initialValues={initialValues}
        onSubmit={() => {}}
        validationSchema={validationSchema}
      >
        {(addSPOrgFormik) => (
          <ConfirmModal
            body={
              <StyledContainer>
                <Form autoComplete="off">
                  <SPAccountDetailsGrid disableOnFormErrors={false} />
                </Form>
                <Spacer y={40} />
                Note: All fields are mandatory!
              </StyledContainer>
            }
            cancelButtonNegative={true}
            disabled={!addSPOrgFormik.dirty || !addSPOrgFormik.isValid}
            header="Add New Organization"
            isOpen={isOpen}
            okButtonNegative={false}
            okButtonText="Continue"
            onClickHandlerForCancel={onClose}
            onClickHandlerForOK={async () => {
              const { error, data } = await addOrg({
                ...addSPOrgFormik.values,
              });
              if (error) {
                toastError(error);
              } else {
                if (data.is_sfdc_linked) {
                  toastSuccess(
                    <>
                      <p>Org successfully created and linked to SFDC.</p>
                      <p>
                        Account ID/Name and Org ID/Name are not editable for SFDC-linked orgs on
                        Estimator.
                      </p>
                    </>
                  );
                }
                push(getLinkForOrganizationsPage(data.org_id));
              }
              onClose();
            }}
          />
        )}
      </Formik>
    </StyledContainer>
  );
};
