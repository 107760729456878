// @flow
import React from 'react';
import { BACKEND_FIELDNAMES } from '@streaming-projects/enums';

import { TextField } from '../../../../formik-utils/FormFields';

export const SPSPSFDCIDContainer = ({
  disableOnFormErrors = null,
  isMandatory = false,
  fieldsConfigToUse = null,
}) => {
  const sfdcFieldConfig = fieldsConfigToUse.find(
    (x) => x.backendFieldName === BACKEND_FIELDNAMES.SP_SFDC_ID
  );
  const { backendFieldName, displayName, icon } = sfdcFieldConfig;
  return (
    <TextField
      disableOnFormErrors={disableOnFormErrors}
      fieldDisplayName={displayName}
      fieldName={backendFieldName}
      icon={icon}
      isMandatory={isMandatory}
    />
  );
};
