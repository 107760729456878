// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.forecast-forecast-header-center .ag-header-cell-label {
    justify-content: center;
}

.forecast-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.forecast-cell-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/streaming-projects/forecast-status-page/styles.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,OAAO;AACX;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":[".forecast-forecast-header-center .ag-header-cell-label {\n    justify-content: center;\n}\n\n.forecast-center {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex: 1;\n}\n\n.forecast-cell-center {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
