// @flow
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { StyledGridRow } from '@src/common-utils/styledComponents';
import { SPSPNameContainer } from '@streaming-projects/sp-page/sp-details/SPSPNameContainer';
import { SPEndDateContainer } from '@streaming-projects/sp-page/sp-details/SPEndDateContainer';
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import { useUpdateStreamingProjectMutation } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { SPStreamingProjectContext } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContext';
import { Spacer } from '@presentational/spacing/Spacer';
import { GenericSaveResetButtons } from '@components/GenericSaveResetButtons';
import { STREAMING_PROJECTS_SP_DETAILS_CONFIG } from '@streaming-projects/sp-page/config';
import { SPSPSFDCIDContainer } from '@streaming-projects/sp-page/sp-details/SPSPSFDCIDContainer';
import { SPProvisionDateContainer } from '@streaming-projects/sp-page/sp-details/SPProvisionDateContainer';
import { SPGoLiveDateContainer } from '@streaming-projects/sp-page/sp-details/SPGoLiveDateContainer';
import { SPRampedDateContainer } from '@streaming-projects/sp-page/sp-details/SPRampedDateContainer';
import {
  DateValidationErrorsDisplayContainer,
  validateDatesEnteredAndReturnErrorsIfAny,
} from '@streaming-projects/sp-page/add-sp/DateValidationErrorsDisplayContainer';
import { useFormikContext } from 'formik';
import { SPBaselineSPGenericDetailsContainer } from '@streaming-projects/sp-page/sp-details/SPBaselineSPGenericDetailsContainer';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';
import { getSPDetailsFieldConfigByAddingSFDCIDValidation } from '@streaming-projects/sp-page/utils';
import { SPSPPhaseContainer } from '@streaming-projects/sp-page/sp-details/SPSPPhaseContainer';
import { SPSPRiskStatusContainer } from '@streaming-projects/sp-page/sp-details/SPSPRiskStatusContainer';

const removeEmptyValues = (object) =>
  Object.fromEntries(Object.entries(object).filter(([_, value]) => value));

export const SPStreamingProjectGenericDetailsContainer = () => {
  const { orgId, spId } = useParams();
  const [updateStreamingProjectDetails] = useUpdateStreamingProjectMutation();
  const spSPData = useContext(SPStreamingProjectContext);
  const { values } = useFormikContext();
  const spOrgData = useContext(SPOrgContext);
  const isBaselineSP = spSPData?.isBaseLineSP ?? false;

  if (isBaselineSP) {
    return <SPBaselineSPGenericDetailsContainer />;
  }

  const areAnyErrorsPresentInProvidedDates =
    validateDatesEnteredAndReturnErrorsIfAny(values).length > 0;

  // todo::SP Remove duplication between this one and SPStreamingProjectTopLevelContainerForm
  // Ideally, the fields config should be set once in the SP context and everyone should be using just that
  // This includes the core components like SPSFDCIDContainer too
  const fieldsConfigToUse = getSPDetailsFieldConfigByAddingSFDCIDValidation(
    spOrgData.isSFDCLinked,
    [...STREAMING_PROJECTS_SP_DETAILS_CONFIG]
  );

  return (
    <>
      <PaddedAndRaisedSegment>
        <Grid columns={2}>
          <StyledGridRow paddingBottom="1rem">
            <Grid.Column width={6}>
              <SPSPNameContainer />
            </Grid.Column>
            <Grid.Column width={6}>
              <SPSPSFDCIDContainer
                fieldsConfigToUse={fieldsConfigToUse}
                isMandatory={spOrgData.isSFDCLinked}
              />
            </Grid.Column>
          </StyledGridRow>

          <StyledGridRow paddingBottom="1rem">
            <Grid.Column width={6}>
              <SPProvisionDateContainer showWarningOnChange={true} />
            </Grid.Column>
            <Grid.Column width={6}>
              <SPGoLiveDateContainer showWarningOnChange={true} />
            </Grid.Column>
          </StyledGridRow>

          <StyledGridRow paddingBottom="1rem">
            <Grid.Column width={6}>
              <SPRampedDateContainer showWarningOnChange={true} />
            </Grid.Column>
            <Grid.Column width={6}>
              <SPEndDateContainer showWarningOnChange={true} />
            </Grid.Column>
          </StyledGridRow>

          <StyledGridRow paddingBottom="1rem">
            <Grid.Column width={6}>
              <SPSPPhaseContainer />
            </Grid.Column>
            <Grid.Column width={6}>
              <SPSPRiskStatusContainer />
            </Grid.Column>
          </StyledGridRow>

          <Spacer y={20} />
          <DateValidationErrorsDisplayContainer />
          <Spacer y={20} />
        </Grid>

        <Spacer y={40} />

        <GenericSaveResetButtons
          disableSaveAdditionalCriteria={areAnyErrorsPresentInProvidedDates}
          fieldsConfig={fieldsConfigToUse}
          onSaveHandler={async (payload) => {
            // todo::SP need to change this and handle data and error cases separately
            // Think about all the other cases where this is done and analyze whether all those places also need changing
            return await updateStreamingProjectDetails({
              orgId,
              spId,
              payload: {
                ...removeEmptyValues(payload), // This logic is required to filter out cleared dates
                // todo::SP Check why is this required here but not in create SP
                org_id: orgId,
                sp_id: spId,
                version: spSPData?.sp?.version,
              },
            });
          }}
        />
      </PaddedAndRaisedSegment>
    </>
  );
};
