// @flow
import { boolean, string } from 'yup';
import { DATE_INPUT_TYPE, TEXT_INPUT_TYPE } from '@src/formik-utils/consts';
import { BACKEND_FIELDNAMES, FRONT_END_DISPLAY_NAMES } from '@streaming-projects/enums';
import { convertLocalToUTCDate, convertUTCToLocalDate } from '@src/common-utils/utils';
import { convertConfigArrayToMap } from '@src/configuration/utils';
import {
  SP_SUMMARY_GRID_FIELDNAMES,
  SP_SUMMARY_GRID_HEADERS,
} from '@streaming-projects/orgs/enums';

export const STREAMING_PROJECT_NAME_CONFIG = {
  displayName: FRONT_END_DISPLAY_NAMES.SP_NAME,
  backendFieldName: BACKEND_FIELDNAMES.SP_NAME,
  inputType: TEXT_INPUT_TYPE,
  validation: string().label(FRONT_END_DISPLAY_NAMES.SP_NAME).required(),
};

export const STREAMING_PROJECTS_SP_DETAILS_CONFIG = [
  STREAMING_PROJECT_NAME_CONFIG,
  {
    displayName: FRONT_END_DISPLAY_NAMES.SP_ID,
    backendFieldName: BACKEND_FIELDNAMES.SP_SFDC_ID,
    inputType: TEXT_INPUT_TYPE,
    validation: string().label(FRONT_END_DISPLAY_NAMES.SP_ID),
  },
  {
    displayName: FRONT_END_DISPLAY_NAMES.SP_END_DATE,
    backendFieldName: BACKEND_FIELDNAMES.SP_END_DATE,
    validation: string().label(FRONT_END_DISPLAY_NAMES.SP_END_DATE).nullable(true),
    inputType: DATE_INPUT_TYPE,
    inputTransformationFunc: convertUTCToLocalDate,
    transformationFunctionToSendToBackEnd: convertLocalToUTCDate,
  },
  {
    displayName: FRONT_END_DISPLAY_NAMES.SP_PROVISION_DATE,
    backendFieldName: BACKEND_FIELDNAMES.SP_PROVISION_DATE,
    validation: string().label(FRONT_END_DISPLAY_NAMES.SP_PROVISION_DATE).nullable(true),
    inputType: DATE_INPUT_TYPE,
    inputTransformationFunc: convertUTCToLocalDate,
    transformationFunctionToSendToBackEnd: convertLocalToUTCDate,
  },
  {
    displayName: FRONT_END_DISPLAY_NAMES.SP_GO_LIVE_DATE,
    backendFieldName: BACKEND_FIELDNAMES.SP_GO_LIVE_DATE,
    validation: string().label(FRONT_END_DISPLAY_NAMES.SP_GO_LIVE_DATE).required(),
    inputType: DATE_INPUT_TYPE,
    inputTransformationFunc: convertUTCToLocalDate,
    transformationFunctionToSendToBackEnd: convertLocalToUTCDate,
  },
  {
    displayName: FRONT_END_DISPLAY_NAMES.SP_RAMPED_DATE,
    backendFieldName: BACKEND_FIELDNAMES.SP_RAMPED_DATE,
    validation: string().label(FRONT_END_DISPLAY_NAMES.SP_RAMPED_DATE).nullable(true),
    inputType: DATE_INPUT_TYPE,
    inputTransformationFunc: convertUTCToLocalDate,
    transformationFunctionToSendToBackEnd: convertLocalToUTCDate,
  },
  {
    displayName: FRONT_END_DISPLAY_NAMES.SP_PHASE,
    backendFieldName: BACKEND_FIELDNAMES.SP_PHASE,
    inputType: TEXT_INPUT_TYPE,
    disabled: true,
  },
  {
    displayName: FRONT_END_DISPLAY_NAMES.SP_RISK_STATUS,
    backendFieldName: BACKEND_FIELDNAMES.SP_RISK_STATUS,
    inputType: TEXT_INPUT_TYPE,
    disabled: true,
  },
];

export const STREAMING_PROJECTS_SP_ENABLE_DISABLE_CONFIG = {
  displayName: SP_SUMMARY_GRID_HEADERS.IS_ENABLED,
  backendFieldName: SP_SUMMARY_GRID_FIELDNAMES.IS_ENABLED,
  validation: boolean().label(SP_SUMMARY_GRID_HEADERS.IS_ENABLED).required(),
};

export const STREAMING_PROJECTS_SP_DETAILS_CONFIG_MAP = convertConfigArrayToMap(
  STREAMING_PROJECTS_SP_DETAILS_CONFIG
);
