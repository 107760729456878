// @flow
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { StyledContainer, StyledSFDCCreateContainer } from '@src/common-utils/styledComponents';
import { StyledH4 } from '@presentational/headings/StyledH4';
import { NegativeMessageContainer } from '@presentational/messages/NegativeMessageContainer';
import { useCreateStreamingProjectFromSFDCMutation } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { QueryError30 } from '@presentational/QueryError30';

export const SFDCSPRecordCreateIntermediaryPage = () => {
  const [error, setError] = useState(null);
  const [paramsValidationError, setParamsValidationError] = useState(null);
  const [createStreamingProjectFromSFDC] = useCreateStreamingProjectFromSFDCMutation();
  const { push } = useHistory();

  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const sfdcAccountId = urlSearchParams.get('sfdc_account_id');
  const sfdcOrgId = urlSearchParams.get('sfdc_cloud_org_id');
  const sfdcSPRecordId = urlSearchParams.get('sfdc_sp_record_id');

  useEffect(() => {
    const createSPE = async () => {
      if (
        !sfdcAccountId ||
        sfdcAccountId.length === 0 ||
        !sfdcSPRecordId ||
        sfdcSPRecordId.length === 0
      ) {
        setParamsValidationError(
          'Could not find a valid Account ID or SP Record ID. Please check!'
        );
        return;
      }

      const { error, data } = await createStreamingProjectFromSFDC({
        payload: {
          sfdc_account_id: sfdcAccountId,
          ...(sfdcOrgId && { sfdc_cloud_org_id: sfdcOrgId }),
          sfdc_sp_record_id: sfdcSPRecordId,
        },
      });

      if (!error) {
        push(`${data.redirection_url}`);
        return;
      }

      setError(error);
    };
    // eslint-disable-next-line no-console
    createSPE().then(() => console.log('SPE Created!'));
  }, [createStreamingProjectFromSFDC, push, sfdcAccountId, sfdcOrgId, sfdcSPRecordId]);

  if (error) {
    return <QueryError30 error={error} />;
  }

  if (paramsValidationError) {
    return (
      <StyledContainer>
        <NegativeMessageContainer
          body={<p>{paramsValidationError}</p>}
          header="Could not create SP Record!"
        />
      </StyledContainer>
    );
  }

  return (
    <StyledSFDCCreateContainer>
      <StyledH4 text={<>SP Record creation is in progress....</>} />
    </StyledSFDCCreateContainer>
  );
};
