// @flow
import { Tooltip } from '@presentational/Tooltip';
import React, { useContext } from 'react';
import { SPStreamingProjectContext } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContext';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';
import { getIfSPPhaseAndRiskStatusAreStale } from '@streaming-projects/sp-page/utils';

export const SPPhaseAndRiskStatusTooltip = () => {
  const spSPData = useContext(SPStreamingProjectContext);
  const spOrgData = useContext(SPOrgContext);

  const isOrgAndAccountSFDCLinked = spOrgData.isSFDCLinked;
  if (!isOrgAndAccountSFDCLinked) {
    return (
      <Tooltip
        flowing={true}
        text="This is captured from SFDC. This streaming project is not linked to SFDC."
      />
    );
  }

  const isPhaseAndRiskStatusStale = getIfSPPhaseAndRiskStatusAreStale(spSPData);
  if (!isPhaseAndRiskStatusStale) {
    return null;
  }

  return <Tooltip flowing={true} text="Stale data. Please check SFDC for accurate data!" />;
};
