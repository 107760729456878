// @flow
import React from 'react';
import { TextField } from '@src/formik-utils/FormFields';

import { STREAMING_PROJECTS_ORG_DETAILS_CONFIG_MAP } from '../config';

export const OrgDetailsTextFormInput = ({ disableOnFormErrors, configKey, disabled = false }) => {
  const { backendFieldName, displayName, icon } =
    STREAMING_PROJECTS_ORG_DETAILS_CONFIG_MAP.get(configKey);
  return (
    <TextField
      disableOnFormErrors={disableOnFormErrors}
      disabled={disabled}
      fieldDisplayName={displayName}
      fieldName={backendFieldName}
      icon={icon}
    />
  );
};

export default OrgDetailsTextFormInput;
