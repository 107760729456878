// @flow
import { Formik } from 'formik';
import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { object } from 'yup';
import { Grid } from 'semantic-ui-react';
import { SPSPNameContainer } from '@streaming-projects/sp-page/sp-details/SPSPNameContainer';
import { SPEndDateContainer } from '@streaming-projects/sp-page/sp-details/SPEndDateContainer';
import { STREAMING_PROJECTS_SP_DETAILS_CONFIG } from '@streaming-projects/sp-page/config';
import { useAddStreamingProjectMutation } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { toastError } from '@presentational/notifications/utils';
import { ConfirmModal } from '@presentational/modals/ConfirmModal';
import { getLinkForStreamingProjectsSPPage } from '@streaming-projects/links';
import { Form } from '@src/formik-utils/formikSUIWrapper';
import { StyledContainer, StyledGridRow } from '@src/common-utils/styledComponents';
import { getValidationSchemaFromColsConfig } from '@src/configuration/utils';
import { extractRelevantFieldValuesFromForm } from '@src/common-utils/utils';
import {
  getSPDetailsFieldConfigByAddingBaselineNameValidation,
  getSPDetailsFieldConfigByAddingSFDCIDValidation,
} from '@streaming-projects/sp-page/utils';
import { SPProvisionDateContainer } from '@streaming-projects/sp-page/sp-details/SPProvisionDateContainer';
import { SPGoLiveDateContainer } from '@streaming-projects/sp-page/sp-details/SPGoLiveDateContainer';
import { SPRampedDateContainer } from '@streaming-projects/sp-page/sp-details/SPRampedDateContainer';
import { SPSPSFDCIDContainer } from '@streaming-projects/sp-page/sp-details/SPSPSFDCIDContainer';
import { Spacer } from '@presentational/spacing/Spacer';
import {
  DateValidationErrorsDisplayContainer,
  validateDatesEnteredAndReturnErrorsIfAny,
} from '@streaming-projects/sp-page/add-sp/DateValidationErrorsDisplayContainer';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';

export const SPSPAddModal = ({ isOpen, setOpen }) => {
  const [addStreamingProject] = useAddStreamingProjectMutation();
  const { push } = useHistory();
  const { orgId } = useParams();
  const spOrgData = useContext(SPOrgContext);

  const initialValues = {};

  // The Add Modal should always validate that the SP Name should not be Baseline
  let fieldsConfigToUse = getSPDetailsFieldConfigByAddingBaselineNameValidation();
  fieldsConfigToUse = getSPDetailsFieldConfigByAddingSFDCIDValidation(
    spOrgData.isSFDCLinked,
    fieldsConfigToUse
  );

  const validationSchema = object({
    ...getValidationSchemaFromColsConfig(fieldsConfigToUse),
  });

  return (
    <StyledContainer data-testid="sp-sp-add-modal">
      <Formik
        autoComplete="off"
        initialValues={initialValues}
        onSubmit={() => {}}
        validationSchema={validationSchema}
      >
        {(addSPSPFormik) => {
          const areAnyErrorsPresentInProvidedDates =
            validateDatesEnteredAndReturnErrorsIfAny(addSPSPFormik.values).length > 0;

          return (
            <ConfirmModal
              body={
                <StyledContainer>
                  <Form autoComplete="off">
                    <Grid columns={2}>
                      <StyledGridRow paddingBottom="1rem">
                        <Grid.Column width={6}>
                          <SPSPNameContainer disableOnFormErrors={false} />
                        </Grid.Column>
                        <Grid.Column width={6}>
                          <SPSPSFDCIDContainer
                            disableOnFormErrors={false}
                            fieldsConfigToUse={fieldsConfigToUse}
                            isMandatory={spOrgData.isSFDCLinked}
                          />
                        </Grid.Column>
                      </StyledGridRow>

                      <StyledGridRow paddingBottom="1rem">
                        <Grid.Column width={6}>
                          <SPProvisionDateContainer
                            disableOnFormErrors={false}
                            showWarningOnChange={false}
                          />
                        </Grid.Column>
                        <Grid.Column width={6}>
                          <SPGoLiveDateContainer
                            disableOnFormErrors={false}
                            showWarningOnChange={false}
                          />
                        </Grid.Column>
                      </StyledGridRow>

                      <StyledGridRow paddingBottom="1rem">
                        <Grid.Column width={6}>
                          <SPRampedDateContainer
                            disableOnFormErrors={false}
                            showWarningOnChange={false}
                          />
                        </Grid.Column>
                        <Grid.Column width={6}>
                          <SPEndDateContainer
                            disableOnFormErrors={false}
                            showWarningOnChange={false}
                          />
                        </Grid.Column>
                      </StyledGridRow>
                      <Spacer y={20} />
                      <DateValidationErrorsDisplayContainer />
                      <Spacer y={20} />
                    </Grid>
                  </Form>
                </StyledContainer>
              }
              cancelButtonNegative={true}
              disabled={
                !addSPSPFormik.dirty || !addSPSPFormik.isValid || areAnyErrorsPresentInProvidedDates
              }
              header="Add New Streaming Project"
              isOpen={isOpen}
              okButtonNegative={false}
              okButtonText="Continue"
              onClickHandlerForCancel={() => {
                addSPSPFormik.resetForm();
                return setOpen(false);
              }}
              onClickHandlerForOK={async () => {
                const { error, data } = await addStreamingProject({
                  orgId,
                  payload: {
                    org_id: orgId,
                    ...extractRelevantFieldValuesFromForm(
                      STREAMING_PROJECTS_SP_DETAILS_CONFIG,
                      addSPSPFormik.values
                    ),
                  },
                });

                if (error) {
                  toastError(error);
                } else {
                  push(getLinkForStreamingProjectsSPPage(orgId, data.sp_id));
                }
                setOpen(false);
              }}
            />
          );
        }}
      </Formik>
    </StyledContainer>
  );
};
