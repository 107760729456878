// @flow
import React, { useContext, useState } from 'react';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';
import { SPStreamingProjectContext } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContext';
import { STREAMING_PROJECTS_SP_DETAILS_CONFIG } from '@streaming-projects/sp-page/config';
import {
  convertLocalToUTCDate,
  convertUTCToLocalDate,
  getFormikFormInputsFromColConfigAndInputSource,
} from '@src/common-utils/utils';
import { StyledContainer } from '@src/common-utils/styledComponents';
import { Form } from '@src/formik-utils/formikSUIWrapper';
import { DeleteButton } from '@presentational/buttons/DeleteButton';
import { SPDeleteWarningModal } from '@streaming-projects/orgs/org-top-level/sp-summary/SPDeleteWarningModal';
import { Spacer } from '@presentational/spacing/Spacer';
import { SPStreamingProjectAccordionsContainer } from '@streaming-projects/sp-page/accordions-container/SPStreamingProjectAccordionsContainer';
import {
  getSPDetailsFieldConfigByAddingBaselineNameValidation,
  getSPDetailsFieldConfigByAddingSFDCIDValidation,
} from '@streaming-projects/sp-page/utils';
import { SPStreamingProjectEnabledContainer } from '@streaming-projects/orgs/org-top-level/sp-summary/enable-disable/SPStreamingProjectEnabledContainer';
import { BACKEND_FIELDNAMES, FRONT_END_DISPLAY_NAMES } from '@streaming-projects/enums';
import { string } from 'yup';
import { DATE_INPUT_TYPE } from '@src/formik-utils/consts';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';

export const SPStreamingProjectTopLevelContainerForm = () => {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const spSPData = useContext(SPStreamingProjectContext);
  const spOrgData = useContext(SPOrgContext);
  const { spId } = useParams();
  const spName = spSPData?.sp?.name;
  const currVersion = spSPData?.sp?.version;
  let spSPInputsSource = {
    name: spName,
    spId,
    sfdc_sp_record_id: spSPData?.sp?.sfdc_sp_record_id ?? '',
    start_date: spSPData?.sp?.start_date ?? null,
    end_date: spSPData?.sp?.end_date ?? null,
    provision_date: spSPData?.sp?.provision_date ?? null,
    ramp_date: spSPData?.sp?.ramp_date ?? null,
    go_live_date: spSPData.sp.go_live_date,
    is_enabled: spSPData.sp.is_enabled,
  };

  let fieldsConfigToUse = STREAMING_PROJECTS_SP_DETAILS_CONFIG;
  if (!spSPData.isBaseLineSP) {
    // This block contains stuff applicable only for normal non-Baseline SPs

    // Phase and Risk Status are only applicable for non-Baseline SPs
    spSPInputsSource = {
      ...spSPInputsSource,
      sp_phase: spOrgData.isSFDCLinked ? spSPData.sp.sfdc_details.phase : 'Not Available',
      sp_risk_status: spOrgData.isSFDCLinked
        ? spSPData.sp.sfdc_details.risk_status
        : 'Not Available',
    };
    // If it is not a Baseline SP, then, add the dynamic validation that SP Name cannot be Baseline
    fieldsConfigToUse = getSPDetailsFieldConfigByAddingBaselineNameValidation();
    // If it is not a Baseline SP, then append the SFDC ID Validation as per whether it is Linked Org/Acc or not
    fieldsConfigToUse = getSPDetailsFieldConfigByAddingSFDCIDValidation(spOrgData.isSFDCLinked, [
      ...fieldsConfigToUse,
    ]);
  } else {
    // This block contains stuff applicable only for Baseline
    // Start Date is only applicable to Baseline
    fieldsConfigToUse = [
      ...fieldsConfigToUse,
      {
        displayName: FRONT_END_DISPLAY_NAMES.SP_START_DATE,
        backendFieldName: BACKEND_FIELDNAMES.SP_START_DATE,
        validation: string().label(FRONT_END_DISPLAY_NAMES.SP_START_DATE).required(),
        inputType: DATE_INPUT_TYPE,
        inputTransformationFunc: convertUTCToLocalDate,
        transformationFunctionToSendToBackEnd: convertLocalToUTCDate,
      },
    ];
  }

  const { initialValues, initialTouched, validationSchema } =
    getFormikFormInputsFromColConfigAndInputSource(fieldsConfigToUse, spSPInputsSource);

  return (
    <StyledContainer>
      <Formik
        enableReinitialize={true}
        initialTouched={initialTouched}
        initialValues={initialValues}
        onSubmit={() => {}}
        validateOnMount={true}
        validationSchema={validationSchema}
      >
        {() => (
          <Form autoComplete="off">
            {!spSPData.isBaseLineSP && (
              <SPStreamingProjectEnabledContainer
                checked={spSPData.sp.is_enabled}
                spId={spId}
                version={currVersion}
              />
            )}
            <DeleteButton
              onClick={() => {
                setDeleteModalOpen(true);
              }}
              size="large"
            />
            <SPDeleteWarningModal
              isOpen={isDeleteModalOpen}
              onClose={() => setDeleteModalOpen(false)}
              spId={spId}
              spName={spName}
              version={currVersion}
            />
            <Spacer y={10} />
            <SPStreamingProjectAccordionsContainer />
          </Form>
        )}
      </Formik>
    </StyledContainer>
  );
};
