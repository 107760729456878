// @flow
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { DataFetcherContainer } from '@presentational/DataFetcherContainer';
import { useGetSPForecastStatusQuery } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { getProcessedForecastStatusData } from '@streaming-projects/forecast-status-page/utils';
import { SPForecastStatusContext } from '@streaming-projects/forecast-status-page/contexts/SPForecastStatusContext';

export const SPForecastStatusContextProvider = ({ children }) => {
  const { orgId } = useParams();

  return (
    <DataFetcherContainer
      dataFetchingArgs={useMemo(
        () => [
          { orgId },
          {
            skip: !orgId,
          },
        ],

        [orgId]
      )}
      dataFetchingFunction={useCallback(useGetSPForecastStatusQuery, [])}
    >
      {(data, _, refetch) => {
        const processedForecastStatusData = getProcessedForecastStatusData(data, orgId);
        return (
          <SPForecastStatusContext.Provider value={{ processedForecastStatusData, refetch }}>
            {children}
          </SPForecastStatusContext.Provider>
        );
      }}
    </DataFetcherContainer>
  );
};
