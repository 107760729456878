// @flow

import { BACKEND_FIELDNAMES, FRONT_END_DISPLAY_NAMES } from '@streaming-projects/enums';

export const GRID_HEADERS = {
  ORGANIZATION_NAME: 'Organization Name',
  ORGANIZATION_LIST: 'Organization List',
  ORGANIZATION_ID: 'Organization Id',
  LINK: 'link',
  RATE_CARD: 'Rate Card',
};

export const GRID_FIELDNAMES = {
  ORGANIZATION_ID: 'organizationId',
  ORGANIZATION_NAME: 'organizationName',
  ORGANIZATION_LIST: 'organizationList',
  LINK: 'link',
  RATE_CARD: 'rateCard',
};

export const ADD_ORGANIZATION = 'Add Organization';

export const SP_SUMMARY_GRID_HEADERS = {
  NAME: 'SP Name',
  ID: 'SP ID',
  SFDC_ID: 'SP SFDC ID',
  START_DATE: 'Start Date',
  SP_PROVISION_DATE: FRONT_END_DISPLAY_NAMES.SP_PROVISION_DATE,
  SP_GO_LIVE_DATE: FRONT_END_DISPLAY_NAMES.SP_GO_LIVE_DATE,
  SP_RAMPED_DATE: FRONT_END_DISPLAY_NAMES.SP_RAMPED_DATE,
  END_DATE: 'End Date',
  VERSION: 'Version',
  IS_ENABLED: 'Enable',
};

export const SP_SUMMARY_GRID_FIELDNAMES = {
  NAME: 'name',
  ID: 'id',
  SFDC_ID: 'sp_sfdc_id',
  START_DATE: 'start_date',
  END_DATE: 'end_date',
  SP_PROVISION_DATE: BACKEND_FIELDNAMES.SP_PROVISION_DATE,
  SP_GO_LIVE_DATE: BACKEND_FIELDNAMES.SP_GO_LIVE_DATE,
  SP_RAMPED_DATE: BACKEND_FIELDNAMES.SP_RAMPED_DATE,
  VERSION: 'version',
  IS_ENABLED: 'is_enabled',
};

export const CLUSTER_LINKING_RESOURCES_GRID_HEADERS = {
  ID: 'Cluster Linking ID',
  NAME: 'Cluster Linking Name',
  SOURCE_CLUSTER_NAME: 'Source Cluster Resource Name',
  DESTINATION_CLUSTER_NAME: 'Destination Cluster Resource Name',
  SOURCE_CLUSTER_ID: 'Source Cluster Resource ID',
  DESTINATION_CLUSTER_ID: 'Destination Cluster Resource ID',
  VERSION: 'Version',
};

export const CLUSTER_LINKING_RESOURCES_GRID_FIELDNAMES = {
  ID: 'id',
  NAME: 'name',
  SOURCE_CLUSTER_NAME: 'source_cluster_name',
  DESTINATION_CLUSTER_NAME: 'destination_cluster_name',
  SOURCE_CLUSTER_ID: 'source_cluster_id',
  DESTINATION_CLUSTER_ID: 'destination_cluster_id',
  VERSION: 'version',
};

export const FLINK_POOL_RESOURCES_GRID_HEADERS = {
  ID: 'Flink Pool ID',
  NAME: 'Pool Name',
  PROVIDER: 'Provider',
  REGION: 'Region',
  VERSION: 'Version',
};

export const FLINK_POOL_RESOURCES_GRID_FIELDNAMES = {
  ID: 'id',
  NAME: 'name',
  PROVIDER: 'provider',
  PROVIDER_LABEL: 'provider_label',
  REGION: 'region',
  REGION_LABEL: 'region_label',
  VERSION: 'version',
};

export const STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS = {
  ID: 'Stream Governance ID',
  ENVIRONMENT_NAME: 'Environment Name',
  PACKAGE_NAME: 'Package',
  PROVIDER: 'Provider',
  REGION: 'Region',
  VERSION: 'Version',
};

export const STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES = {
  ID: 'id',
  ENVIRONMENT_NAME: 'name',
  PACKAGE_NAME: 'package',
  PROVIDER: 'provider',
  PROVIDER_LABEL: 'provider_label',
  REGION: 'region',
  REGION_LABEL: 'region_label',
  VERSION: 'version',
};

export const CLUSTER_RESOURCES_GRID_HEADERS = {
  ID: 'Cluster ID',
  NAME: 'Name',
  NETWORKING_TYPE: 'Networking Type',
  TYPE: 'Type',
  AVAILABILITY: 'Availability',
  PROVIDER: 'Provider',
  REGION: 'Region',
  SLA: 'SLA',
  VERSION: 'Version',
};

export const CLUSTER_RESOURCES_GRID_FIELDNAMES = {
  ID: 'id',
  NAME: 'name',
  NETWORKING_TYPE: 'networking_type',
  CLUSTER_TYPE: 'cluster_type',
  AVAILABILITY: 'availability',
  PROVIDER: 'provider',
  REGION: 'region',
  SLA: 'sla',
  VERSION: 'version',
  REGION_LABEL: 'region_label',
  CLUSTER_TYPE_LABEL: 'cluster_type_label',
  AVAILABILITY_LABEL: 'availability_label',
  PROVIDER_LABEL: 'provider_label',
  NETWORKING_TYPE_LABEL: 'networking_type_label',
};

export const FLINK_WORKLOADS_INPUTS_FIELDNAMES = {
  WORKLOAD_NAME: 'name',
  IS_ENABLED: 'is_enabled',
  MONTHLY_INPUTS: 'flink_workload_monthly_inputs',
};

export const FLINK_WORKLOADS_INPUTS_DISPLAY_NAMES = {
  WORKLOAD_NAME: 'Workload Name',
  IS_ENABLED: 'Enable',
  MONTHLY_INPUTS: 'Flink Workload Monthly Inputs',
};

export const CLUSTER_LINKING_WORKLOADS_INPUTS_FIELDNAMES = {
  WORKLOAD_NAME: 'name',
  IS_ENABLED: 'is_enabled',
  MONTHLY_INPUTS: 'cluster_linking_workload_monthly_inputs',
};

export const CLUSTER_LINKING_WORKLOADS_INPUTS_DISPLAY_NAMES = {
  WORKLOAD_NAME: 'Workload Name',
  IS_ENABLED: 'Enable',
  MONTHLY_INPUTS: 'Cluster Linking Workload Monthly Inputs',
};

export const STREAM_GOVERNANCE_WORKLOADS_INPUTS_FIELDNAMES = {
  WORKLOAD_NAME: 'name',
  IS_ENABLED: 'is_enabled',
  MONTHLY_INPUTS: 'sg_workload_monthly_inputs',
};

export const STREAM_GOVERNANCE_WORKLOADS_INPUTS_DISPLAY_NAMES = {
  WORKLOAD_NAME: 'Workload Name',
  IS_ENABLED: 'Enable',
  MONTHLY_INPUTS: 'SG Workload Monthly Inputs',
};

export const CLUSTER_WORKLOADS_INPUTS_FIELDNAMES = {
  WORKLOAD_NAME: 'name',
  IS_ENABLED: 'is_enabled',
  CLUSTER_RETENTION: 'retention_days',
  CLUSTER_RETENTION_INFINITE: 'retention_infinite',
  FOLLOWER_FETCH: 'is_follower_fetch_enabled',
  EXISTING_STORAGE: 'existing_storage',
  CKU: 'cku',
};

export const CLUSTER_WORKLOADS_INPUTS_DISPLAY_NAMES = {
  WORKLOAD_NAME: 'Workload Name',
  IS_ENABLED: 'Enable',
  MONTHLY_INPUTS: 'Cluster Workload Monthly Inputs',
  CLUSTER_RETENTION: 'Retention (days)',
  CLUSTER_RETENTION_INFINITE: 'Infinite Retention',
  FOLLOWER_FETCH: 'Follow-Fetch (available on VPC 2.0 only)',
  EXISTING_STORAGE: 'Existing Storage',
  CKU: 'Existing CKU',
};

/** Commit Details */

// CTODO::SP Check whether the accessors are correct once the API is ready
export const COMMIT_DETAILS_GRID_FIELDNAMES = {
  ID: 'commit_id',
  NAME: 'name',
  START_DATE: 'start_date',
  END_DATE: 'end_date',
  COMMIT_AMOUNT: 'user_entered_commit_amount',
};

export const COMMIT_DETAILS_GRID_HEADERS = {
  ID: 'ID',
  NAME: 'Commit Name',
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
  COMMIT_AMOUNT: 'Commit Amount Entered',
};
