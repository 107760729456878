// @flow
import { useParams } from 'react-router-dom';
import React from 'react';
import { useCopyStreamingProjectMutation } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { CopyRenderer } from '@components/views/grid-utils/renderers';
import { toastError, toastSuccess } from '@presentational/notifications/utils';

export const SPStreamingProjectCopyRenderer = ({ data: { id } }) => {
  const { orgId } = useParams();

  const [copyStreamingProject] = useCopyStreamingProjectMutation();

  return (
    <CopyRenderer
      onClickHandler={async () => {
        const { error } = await copyStreamingProject({
          orgId,
          spId: id,
        });

        if (error) {
          toastError(error);
        } else {
          toastSuccess('Successfully copied the streaming project!');
        }
      }}
    />
  );
};
