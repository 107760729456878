// @flow
import {
  FORECAST_STATUS_FIELD_NAMES,
  FORECAST_STATUS_GRID_HEADERS,
} from '@streaming-projects/forecast-status-page/enums';
import { useSubmitRecalcRequestForStreamingProjectMutation } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { SP_PRICING_STATUS_STATES } from '@src/constants';
import { CurrencyZeroDPRenderer } from '@components/views/grid-utils/renderers';
import { ButtonWithPopup } from '@presentational/ButtonWithPopup';
import React from 'react';

import { getCalcStatusBtnPopupContent } from './utils';

export const StatusCellRenderer = ({ data }) => {
  const status = data[FORECAST_STATUS_FIELD_NAMES.CALCULATION_STATUS];
  const [submitRecalcRequest] = useSubmitRecalcRequestForStreamingProjectMutation();

  const handleRecalcRequest = () => {
    submitRecalcRequest({
      spId: data[FORECAST_STATUS_FIELD_NAMES.ID],
      orgId: data[FORECAST_STATUS_FIELD_NAMES.ORG_ID],
    });
  };

  const isFailed = status === SP_PRICING_STATUS_STATES.FAILED;

  return (
    <>
      {status === SP_PRICING_STATUS_STATES.SUCCESS ? (
        <CurrencyZeroDPRenderer value={data[FORECAST_STATUS_GRID_HEADERS.TOTAL_SPEND_DISCOUNTED]} />
      ) : (
        <ButtonWithPopup
          circular={true}
          color={isFailed ? 'red' : null}
          disabled={
            status === SP_PRICING_STATUS_STATES.IN_PROGRESS ||
            status === SP_PRICING_STATUS_STATES.QUEUED
          }
          onClick={handleRecalcRequest}
          popupContent={getCalcStatusBtnPopupContent(status)}
          size="mini"
          text={isFailed ? 'Click to Retry...' : 'Re-Calculate Now!'}
        />
      )}
    </>
  );
};
