// @flow

import React, { useContext, useState } from 'react';
import {
  CLUSTER_RESOURCES_GRID_FIELDNAMES,
  CLUSTER_RESOURCES_GRID_HEADERS,
} from '@streaming-projects/orgs/enums';
import { StyledContainer } from '@src/common-utils/styledComponents';
import { ConfirmModal } from '@presentational/modals/ConfirmModal';
import { DataGrid } from '@components/views/grid-utils/DataGrid';
import {
  useAddClusterWorkloadMutation,
  useGetAvailableClusterResourcesQuery,
} from '@streaming-projects/service-definitions/streamingProjectsApi';
import { handleGridRowSelectionChanged } from '@src/common-utils/utils';
import { useHistory, useParams } from 'react-router-dom';
import { SPStreamingProjectContext } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContext';
import { toastError } from '@presentational/notifications/utils';
import { getLinkForStreamingProjectsClusterWorkloadsPage } from '@streaming-projects/links';
import { SPMetaDataContext } from '@streaming-projects/orgs/contexts/SPMetaDataContext';
import {
  getMappedDataValueGetter,
  getRegionName,
} from '@streaming-projects/resource-definitions/auxilary-components/MappedDataRenderer';
import {
  PercentageRenderer,
  ResourceSelectionRadioButtonRenderer,
} from '@components/views/grid-utils/renderers';
import { ADD_NEW_WORKLOAD_MESSAGE, NO_FREE_RESOURCE_MESSAGE } from '@streaming-projects/constants';
import { QueryError } from '@presentational/QueryError';

export const SPClusterWorkloadAddModal = ({ isOpen, setOpen }) => {
  const { orgId, spId } = useParams();
  const { providerRegionsMap, resourceConfigurationsNameLabelsMap, providerNameLabelMap } =
    useContext(SPMetaDataContext).metaData;

  const [addClusterWorkload] = useAddClusterWorkloadMutation();
  const { push } = useHistory();

  const spStreamingProjectData = useContext(SPStreamingProjectContext);

  const [selectedRow, setSelectedRow] = useState(null);

  const columnDefs = [
    {
      field: 'Selection',
      sortable: false,
      filter: false,
      cellRenderer: (props) =>
        ResourceSelectionRadioButtonRenderer(props, selectedRow, CLUSTER_RESOURCES_GRID_HEADERS.ID),
    },
    {
      field: CLUSTER_RESOURCES_GRID_HEADERS.ID,
      backEndFieldName: CLUSTER_RESOURCES_GRID_FIELDNAMES.ID,
      hide: true,
    },
    {
      field: CLUSTER_RESOURCES_GRID_HEADERS.NAME,
      backEndFieldName: CLUSTER_RESOURCES_GRID_FIELDNAMES.NAME,
    },
    {
      field: CLUSTER_RESOURCES_GRID_HEADERS.TYPE,
      backEndFieldName: CLUSTER_RESOURCES_GRID_FIELDNAMES.CLUSTER_TYPE,
      valueGetter: getMappedDataValueGetter(
        resourceConfigurationsNameLabelsMap[CLUSTER_RESOURCES_GRID_FIELDNAMES.CLUSTER_TYPE]
      ),
    },
    {
      field: CLUSTER_RESOURCES_GRID_HEADERS.NETWORKING_TYPE,
      backEndFieldName: CLUSTER_RESOURCES_GRID_FIELDNAMES.NETWORKING_TYPE,
      valueGetter: getMappedDataValueGetter(
        resourceConfigurationsNameLabelsMap[CLUSTER_RESOURCES_GRID_FIELDNAMES.NETWORKING_TYPE]
      ),
    },
    {
      field: CLUSTER_RESOURCES_GRID_HEADERS.PROVIDER,
      backEndFieldName: CLUSTER_RESOURCES_GRID_FIELDNAMES.PROVIDER,
      valueGetter: getMappedDataValueGetter(providerNameLabelMap),
    },
    {
      field: CLUSTER_RESOURCES_GRID_HEADERS.REGION,
      backEndFieldName: CLUSTER_RESOURCES_GRID_FIELDNAMES.REGION,
      tooltipValueGetter: (params) => getRegionName(params, providerRegionsMap),
      valueGetter: (params) => getRegionName(params, providerRegionsMap),
    },
    {
      field: CLUSTER_RESOURCES_GRID_HEADERS.AVAILABILITY,
      backEndFieldName: CLUSTER_RESOURCES_GRID_FIELDNAMES.AVAILABILITY,
      valueGetter: getMappedDataValueGetter(
        resourceConfigurationsNameLabelsMap[CLUSTER_RESOURCES_GRID_FIELDNAMES.AVAILABILITY]
      ),
    },
    {
      field: CLUSTER_RESOURCES_GRID_HEADERS.SLA,
      backEndFieldName: CLUSTER_RESOURCES_GRID_FIELDNAMES.SLA,
      cellRenderer: PercentageRenderer,
    },
  ];

  const onSelectionChanged = handleGridRowSelectionChanged(
    (rowData) => {
      setSelectedRow(rowData);
    },
    () => setSelectedRow(null)
  );

  // todo::SP Make this common across all 4 DSP elems
  // Note: We cannot use DataFetcherComponent here as we need more control
  const { currentData, error, isLoading, isFetching } = useGetAvailableClusterResourcesQuery(
    { orgId, spId },
    {
      refetchOnMountOrArgChange: true,
      skip: !orgId || !isOpen,
    }
  );

  if (isLoading || isFetching || !isOpen) {
    return null;
  }

  if (error) {
    return <QueryError error={error} />;
  }

  const resourcesToUse = currentData?.resources?.cluster_resources ?? [];

  return (
    <StyledContainer>
      <ConfirmModal
        body={
          <StyledContainer>
            <DataGrid
              columnDefs={columnDefs}
              inputDataSource={resourcesToUse}
              noDataMessage={NO_FREE_RESOURCE_MESSAGE}
              onSelectionChanged={onSelectionChanged}
              rowSelection="single"
              sizeColumnsToFitInitially={true}
              tooltipShowDelay={0}
            />
          </StyledContainer>
        }
        cancelButtonNegative={true}
        disabled={!selectedRow}
        fullScreen={true}
        header={ADD_NEW_WORKLOAD_MESSAGE}
        isOpen={isOpen}
        okButtonNegative={false}
        okButtonText="Continue"
        onClickHandlerForCancel={() => {
          setSelectedRow(null);
          setOpen(false);
        }}
        onClickHandlerForOK={async () => {
          const { error, data } = await addClusterWorkload({
            orgId,
            spId,
            payload: {
              name: 'Default',
              cluster_resource_id: selectedRow[CLUSTER_RESOURCES_GRID_HEADERS.ID],
              sp_version: spStreamingProjectData?.sp?.version,
            },
          });
          if (error) {
            toastError(error);
          } else {
            push(getLinkForStreamingProjectsClusterWorkloadsPage(orgId, spId, data.id));
          }
          setOpen(false);
        }}
      />
    </StyledContainer>
  );
};
