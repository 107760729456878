// @flow
import { StyledGridRow } from '@src/common-utils/styledComponents';
import { NegativeMessageContainer } from '@presentational/messages/NegativeMessageContainer';
import React from 'react';
import { useFormikContext } from 'formik';
import { convertLocalToUTCDate } from '@src/common-utils/utils';
import { Grid } from 'semantic-ui-react';

const validatePairForALessThanB = (firstDate, secondDate, firstDateTag, secondDateTag, errors) => {
  if (firstDate && secondDate && firstDate > secondDate) {
    errors.push(`${firstDateTag} must be less than or equal to ${secondDateTag}.`);
  }
};

export const validateDatesEnteredAndReturnErrorsIfAny = (values) => {
  const errors = [];

  const provisionDate = values.provision_date ? convertLocalToUTCDate(values.provision_date) : null;
  const goLiveDate = values.go_live_date ? convertLocalToUTCDate(values.go_live_date) : null;
  const rampDate = values.ramp_date ? convertLocalToUTCDate(values.ramp_date) : null;
  const endDate = values.end_date ? convertLocalToUTCDate(values.end_date) : null;

  // Validate the order of the dates
  validatePairForALessThanB(provisionDate, goLiveDate, 'Provision Date', 'Go Live Date', errors);
  validatePairForALessThanB(provisionDate, rampDate, 'Provision Date', 'Ramp Date', errors);
  validatePairForALessThanB(provisionDate, endDate, 'Provision Date', 'End Date', errors);
  validatePairForALessThanB(goLiveDate, rampDate, 'Go Live Date', 'Ramp Date', errors);
  validatePairForALessThanB(goLiveDate, endDate, 'Go Live Date', 'End Date', errors);
  validatePairForALessThanB(rampDate, endDate, 'Ramp Date', 'End Date', errors);

  return errors;
};

export const DateValidationErrorsDisplayContainer = () => {
  const { values } = useFormikContext();

  const errors = validateDatesEnteredAndReturnErrorsIfAny(values);

  if (errors.length === 0) {
    return null;
  }

  return (
    <>
      {errors.map((error) => {
        return (
          <StyledGridRow key={error} paddingBottom="1rem">
            <Grid.Column width={12}>
              <NegativeMessageContainer header={error} />
            </Grid.Column>
          </StyledGridRow>
        );
      })}
    </>
  );
};
