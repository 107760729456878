// @flow
import React from 'react';
import { DataGrid } from '@components/views/grid-utils/DataGrid';
import { Spacer } from '@presentational/spacing/Spacer';
import { BACKEND_FIELDNAMES } from '@streaming-projects/enums';
import { GRID_FIELDNAMES, GRID_HEADERS } from '@streaming-projects/orgs/enums';
import { OrganizationNameRenderer } from '@streaming-projects/home-page/hierarchy-view/auxilary-components/OrganizationNameRenderer';

export const SFDCOrganizationsGrid = ({ parentData }) => {
  const inputDataSource = Object.values(parentData.Account.children);

  // todo::SP export and use this as common config
  const ORG_COLUMN_DEFS = [
    {
      field: GRID_FIELDNAMES.ORGANIZATION_NAME,
      backEndFieldName: BACKEND_FIELDNAMES.NAME,
      headerName: GRID_HEADERS.ORGANIZATION_NAME,
      width: 300,
      cellRenderer: ({ data: { link, organizationName } }) => (
        <OrganizationNameRenderer
          highlightTerm=""
          link={link}
          organizationName={organizationName}
        />
      ),
    },
    {
      field: GRID_FIELDNAMES.ORGANIZATION_ID,
      backEndFieldName: BACKEND_FIELDNAMES.ID,
      headerName: GRID_HEADERS.ORGANIZATION_ID,
    },
    {
      field: GRID_FIELDNAMES.LINK,
      backEndFieldName: BACKEND_FIELDNAMES.LINK,
      headerName: GRID_HEADERS.LINK,
      hide: true,
    },
    {
      field: GRID_FIELDNAMES.RATE_CARD,
      backEndFieldName: BACKEND_FIELDNAMES.RATE_CARD,
      headerName: GRID_HEADERS.RATE_CARD,
    },
  ];

  return (
    <>
      <DataGrid
        columnDefs={ORG_COLUMN_DEFS}
        inputDataSource={inputDataSource}
        label={GRID_HEADERS.ORGANIZATION_LIST}
        sizeColumnsToFitInitially={true}
      />
      <Spacer y={5} />
    </>
  );
};
