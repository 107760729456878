// @flow
import React from 'react';

import { QueryError } from './QueryError';

export const DataFetcherContainer = React.memo(
  ({ dataFetchingFunction, dataFetchingArgs, children, throwOnError = true }) => {
    const { currentData, error, refetch } = dataFetchingFunction.call(null, ...dataFetchingArgs);

    // Note: Loading Indicator showing is handled globally by ShowLoaderIfLoadingOrChildren

    if (error) {
      if (throwOnError) {
        return <QueryError error={error} />;
      } else {
        return <>{children(null, error)}</>;
      }
    }

    return <>{currentData && children(currentData, null, refetch)}</>;
  }
);
