// @flow

import React, { useContext, useState } from 'react';
import {
  STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES,
  STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS,
} from '@streaming-projects/orgs/enums';
import { StyledContainer } from '@src/common-utils/styledComponents';
import { ConfirmModal } from '@presentational/modals/ConfirmModal';
import { DataGrid } from '@components/views/grid-utils/DataGrid';
import {
  useAddStreamGovernanceWorkloadMutation,
  useGetAvailableStreamGovernanceResourcesQuery,
} from '@streaming-projects/service-definitions/streamingProjectsApi';
import { handleGridRowSelectionChanged } from '@src/common-utils/utils';
import { useHistory, useParams } from 'react-router-dom';
import { SPStreamingProjectContext } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContext';
import { toastError } from '@presentational/notifications/utils';
import { getLinkForStreamingProjectsSGWorkloadsPage } from '@streaming-projects/links';
import { SPMetaDataContext } from '@streaming-projects/orgs/contexts/SPMetaDataContext';
import {
  getMappedDataValueGetter,
  getRegionName,
} from '@streaming-projects/resource-definitions/auxilary-components/MappedDataRenderer';
import { ADD_NEW_WORKLOAD_MESSAGE, NO_FREE_RESOURCE_MESSAGE } from '@streaming-projects/constants';
import { ResourceSelectionRadioButtonRenderer } from '@components/views/grid-utils/renderers';
import { QueryError } from '@presentational/QueryError';

export const SPStreamGovernanceWorkloadAddModal = ({ isOpen, setOpen }) => {
  const { orgId, spId } = useParams();
  const { providerRegionsMap, providerNameLabelMap } = useContext(SPMetaDataContext).metaData;
  const [addSGWorkload] = useAddStreamGovernanceWorkloadMutation();
  const { push } = useHistory();

  const spStreamingProjectData = useContext(SPStreamingProjectContext);

  const [selectedRow, setSelectedRow] = useState(null);

  const columnDefs = [
    {
      field: 'Selection',
      sortable: false,
      filter: false,
      cellRenderer: (props) =>
        ResourceSelectionRadioButtonRenderer(
          props,
          selectedRow,
          STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS.ID
        ),
    },
    {
      field: STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS.ID,
      backEndFieldName: STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES.ID,
      hide: true,
    },
    {
      field: STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS.ENVIRONMENT_NAME,
      backEndFieldName: STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES.ENVIRONMENT_NAME,
    },
    {
      field: STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS.PACKAGE_NAME,
      backEndFieldName: STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES.PACKAGE_NAME,
    },
    {
      field: STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS.PROVIDER,
      backEndFieldName: STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES.PROVIDER,
      valueGetter: getMappedDataValueGetter(providerNameLabelMap),
    },
    {
      field: STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS.REGION,
      backEndFieldName: STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES.REGION,
      tooltipValueGetter: (params) => getRegionName(params, providerRegionsMap),
      valueGetter: (params) => getRegionName(params, providerRegionsMap),
    },
  ];

  const onSelectionChanged = handleGridRowSelectionChanged(
    (rowData) => setSelectedRow(rowData),
    () => setSelectedRow(null)
  );

  // Note: We cannot use DataFetcherComponent here as we need more control
  const { currentData, error, isLoading, isFetching } =
    useGetAvailableStreamGovernanceResourcesQuery(
      { orgId, spId },
      {
        refetchOnMountOrArgChange: true,
        skip: !orgId || !isOpen,
      }
    );

  if (isLoading || isFetching || !isOpen) {
    return null;
  }

  if (error) {
    return <QueryError error={error} />;
  }

  const resourcesToUse = currentData?.resources?.sg_resources ?? [];

  return (
    <StyledContainer>
      <ConfirmModal
        body={
          <StyledContainer>
            <DataGrid
              columnDefs={columnDefs}
              inputDataSource={resourcesToUse}
              noDataMessage={NO_FREE_RESOURCE_MESSAGE}
              onSelectionChanged={onSelectionChanged}
              rowSelection="single"
              sizeColumnsToFitInitially={true}
              tooltipShowDelay={0}
            />
          </StyledContainer>
        }
        cancelButtonNegative={true}
        disabled={!selectedRow}
        fullScreen={true}
        header={ADD_NEW_WORKLOAD_MESSAGE}
        isOpen={isOpen}
        okButtonNegative={false}
        okButtonText="Continue"
        onClickHandlerForCancel={() => {
          setSelectedRow(null);
          setOpen(false);
        }}
        onClickHandlerForOK={async () => {
          const { error, data } = await addSGWorkload({
            orgId,
            spId,
            payload: {
              name: 'Default',
              stream_governance_resource_id:
                selectedRow[STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS.ID],
              sp_version: spStreamingProjectData?.sp?.version,
            },
          });
          if (error) {
            toastError(error);
          } else {
            push(
              getLinkForStreamingProjectsSGWorkloadsPage(orgId, spId, data.stream_governance_id)
            );
          }
          setOpen(false);
        }}
      />
    </StyledContainer>
  );
};
