// @flow
import React from 'react';
import { SP_PRICING_STATUS_STATES } from '@src/constants';

export const getProcessedForecastStatusData = (data, orgId) =>
  data.streaming_projects
    // we need to filter out disabled SP's
    .filter(({ enabled }) => enabled)
    .map((spStatusRow) => ({
      ...spStatusRow,
      total_spend: spStatusRow.spend_status?.total_spend,
      total_spend_discounted: spStatusRow.spend_status?.total_spend_discounted,
      last_calc_time: spStatusRow.spend_status?.last_calc_time,
      calculation_status: spStatusRow.spend_status?.status,
      org_id: orgId,
    }));

export const getCalcStatusBtnPopupContent = (status) => {
  switch (status) {
    case SP_PRICING_STATUS_STATES.FAILED:
      return (
        <span>
          Previous run failed. Please try again. If the problem persists, please contact
          #cloud-commitment-estimator channel
        </span>
      );
    case SP_PRICING_STATUS_STATES.PENDING:
      return (
        <span>
          You have made some edits! Please click on Re-Calculate Now to fetch the latest data!
        </span>
      );
    case SP_PRICING_STATUS_STATES.IN_PROGRESS:
    case SP_PRICING_STATUS_STATES.QUEUED:
      return (
        <span>
          Note: Repricing is still happening! Please wait while the reprice finishes. You can
          refresh the page periodically to get the latest status.
        </span>
      );
    default:
      return null;
  }
};

export const getForecastMRRBtnPopupContent = (isSFDCLinked, isLastPushedDataStillValid, data) => {
  if (!isSFDCLinked) {
    return <span>You have not linked your org to Salesforce account.</span>;
  }
  switch (data.calculation_status) {
    case SP_PRICING_STATUS_STATES.FAILED:
      return <span>Pricing calculation failed. Please try again before pushing to SFDC!.</span>;
    case SP_PRICING_STATUS_STATES.PENDING:
      return (
        <span>
          You have made some edits! Please click on Re-Calculate Now to fetch the latest pricing
          before pushing to SFDC!
        </span>
      );
    case SP_PRICING_STATUS_STATES.IN_PROGRESS:
    case SP_PRICING_STATUS_STATES.QUEUED:
      return (
        <span>
          Note: Repricing is still happening! Please wait while the reprice finishes, before pushing
          to SFDC!.
        </span>
      );
    default:
      if (isLastPushedDataStillValid) {
        return (
          <span>Note: Disabled because no edits were made after the last successful push!</span>
        );
      }
      return null;
  }
};
